import { Col, Divider, Modal, Row, Space, Typography } from 'antd'
import { ITransaction } from '../../models/transaction.modal'
import { formatCurrency } from '../../utils/string-utils'
const { Text } = Typography

type FieldModalView = {
    modalView: { data: ITransaction | null, isOpen: boolean }
    setModalView: Function
}

export default function ModalView({ modalView, setModalView }: FieldModalView) {
    return (
        <Modal
            title='Chi tiết giao dịch'
            open={modalView.isOpen}
            footer={[]}
            onCancel={() => setModalView({ data: null, isOpen: false })}>
            <Divider />
            <Row>
                <Col span={12}>
                    <Space>
                        <Text strong>Khách hàng:</Text>
                        {modalView.data?.msisdn}
                    </Space>
                </Col>
                <Col span={12}>
                    <Space>
                        <Text strong>Số điện thoại:</Text>
                        {modalView.data?.customer}
                    </Space>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={12}>
                    <Text strong>Gói cước:</Text>
                    <p>Tên gói: {modalView.data?.package?.name}</p>
                    <p>Mã gói: {modalView.data?.package?.code}</p>
                    <p>Giá: {formatCurrency(Number(modalView.data?.package?.price))}</p>
                    <p>Hạn sử dụng: {modalView.data?.package?.numberDay?.toString()} ngày</p>
                </Col>
                <Col span={12}>
                    <Text strong>Công ty:</Text>
                    <p>Tên công ty: {modalView.data?.company?.name}</p>
                    <p>Mã công ty: {modalView.data?.company?.code}</p>
                    <Divider />
                    <Space>
                        <Text strong>Ngày cập nhật:</Text>
                        {modalView.data?.modify}
                    </Space>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={12}>
                    <Space>
                        <Text strong>Ngày bắt đầu:</Text>
                        {modalView.data?.startDate}
                    </Space>
                </Col>
                <Col span={12}>
                    <Space>
                        <Text strong>Ngày kết thúc:</Text>
                        {modalView.data?.endDate}
                    </Space>
                </Col>
            </Row>
        </Modal>
    )
}
