import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, Modal, Select, Space, TableProps, Tag, Upload, message } from 'antd'
import { MoneyCollectOutlined, UploadOutlined, EyeOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { toastError, toastSuccess } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import { Link } from 'react-router-dom'
import { CARD_SIM, DETAIL_TRANSACTION } from '../../assets/constants/route_path'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { orderStatusOptions } from '../../assets/constants/default_options'
import FormSearch from '../../components/form'
import { kitSims } from '../../mocks/kit-sim'
import { apiGetListCompanies } from '../../api'
import { ICompany } from '../../models/company.modal'
import moment from 'moment'

export default function CardSimOrder() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [orderTopups, setOrderTopups] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        status: '',
    })
    const [modalStatus, setModalStatus] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalUpload, setModalUpload] = useState<boolean>(false)
    const [fileExcel, setFileExcel] = useState<any>()
    const [fileList, setFileList] = useState([])
    const [descExcel, setDescExcel] = useState<any>()

    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'orderId',
            title: 'Mã đơn hàng',
            dataIndex: 'orderId',
            render: orderId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {orderId}
                </span>
            ),
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự tạo',
            dataIndex: 'ownerBy',
        },
        {
            key: 'description',
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            key: 'companyName',
            title: 'Công ty',
            dataIndex: 'companyName',
        },
        {
            key: 'total',
            title: 'Tổng số card sim',
            dataIndex: 'total',
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    <Link to={CARD_SIM + DETAIL_TRANSACTION + `?orderId=${data.orderId}`}>
                        <Button
                            icon={<EyeOutlined />}
                            style={{ background: '#289e45', color: 'white' }}
                        >
                            Chi tiết
                        </Button>
                    </Link>
                    <Button
                        disabled={data.status !== 'CREATED'}
                        onClick={() => setModalStatus({ data, isOpen: true })}
                        icon={<MoneyCollectOutlined />}
                        type='primary'
                    >
                        Đấu nối
                    </Button>
                </Space>
            ),
        },
    ]

    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === 'COMPLETED') return <Tag style={style} color="#9b9b05">Hoàn thành</Tag>
        if (state === 'CANCELLED') return <Tag style={style} color="#c93c3c">Đã huỷ bỏ</Tag>
        if (state === 'CREATED') return <Tag style={style} color="#2faac6">Đã tạo</Tag>
        if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
        return <Tag style={style} color="#ff6f00">Đang nạp excel</Tag>
    }

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const respOrder = { data: kitSims }
            console.log(currentPage, pageSize, search)
            if (respOrder.data === null) {
                setOrderTopups({})
                return
            }
            setOrderTopups(respOrder.data)

            const respCompanies = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (respCompanies.data === null) {
                return
            }

            const resp = { total: respOrder.data.total, orders: [] as any }
            const listOrders = respOrder.data.orders
            const listCompanies = respCompanies.data.companies
            for (let i = 0; i < listOrders.length; i++) {
                const order = listOrders[i]
                const companyInfo = listCompanies.find((company: ICompany) => company.id === order.company_id)
                resp.orders.push({
                    ...order,
                    companyName: companyInfo?.name,
                })
            }
            setOrderTopups(resp)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const fetchCompanies = useCallback(async () => {
        try {
            const resPackages = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resPackages.data != null) {
                const options = resPackages.data.companies.map((item: ICompany) => ({
                    label: `${item.id} - ${item.name}`,
                    value: item.id
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchData()
        fetchCompanies()
    }, [fetchData, fetchCompanies])

    const handleSearch = ({ status }: any) => {
        if (status) {
            setSearch({ status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleTopupOrderId = async () => {
        try {
            setIsLoading(true)
            toastSuccess('Đấu nối thành công!')
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }
    const handleCloseModalTopupOrderId = () => {
        setModalStatus({ data: null, isOpen: false })
    }

    const handleUploadFile = async () => {
        if (!fileExcel || !descExcel) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("file", fileExcel.file)
            toastSuccess('Nạp file excel thành công!')
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }
    const handleCloseModalUploadFile = () => {
        setModalUpload(false)
        setFileExcel({})
        setDescExcel('')
    }
    const checkUploadFile = (file: any) => {
        const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
        if (!isExcel) {
            message.error('Vui lòng upload file dạng excel!')
            return Upload.LIST_IGNORE
        }
        return false
    }
    const handleChangeFile = (value: any) => {
        setFileList(value.fileList)
        setFileExcel(value)
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='company_id' label='Công ty'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='status' label='Trạng thái'>
                    <Select
                        style={{ width: 170 }}
                        showSearch
                        placeholder="Chọn trạng thái"
                        options={orderStatusOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
            </FormSearch>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Button onClick={() => setModalUpload(true)} type='primary'>Import đơn hàng (File Excel)</Button>
            </div>
            <CustomTable
                namePage='đơn hàng'
                columns={columns}
                dataSource={orderTopups.orders}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={orderTopups.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Đấu nối' open={modalStatus.isOpen}
                onCancel={handleCloseModalTopupOrderId}
                footer={[
                    <Button onClick={handleTopupOrderId}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTopupOrderId}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nạp tiền cho đơn hàng <b>{modalStatus.data?.orderId}</b></p>
                <Divider />
            </Modal>
            <Modal title='Upload' open={modalUpload}
                onCancel={handleCloseModalUploadFile}
                footer={[
                    <Button onClick={handleUploadFile}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalUploadFile}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Chọn công ty</p>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="Chọn công ty"
                    options={companyOptions}
                    filterOption={filterOptionByLabel}
                />
                <p>Đăng tải File Excel</p>
                <Upload
                    maxCount={1}
                    fileList={fileList}
                    onChange={handleChangeFile}
                    beforeUpload={checkUploadFile}
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                <p>Nhập mô tả</p>
                <Input value={descExcel} onChange={e => setDescExcel(e.target.value)} />
                <Divider />
                <p><b>Ghi chú</b></p>
                <p>Định dạng: Msisdn, SerialSim</p>
                <p>Tên sheet: Sheet1</p>
                <p>Tối đa: 1.000 sim</p>
                <Divider />
            </Modal>
        </div>
    )
}
