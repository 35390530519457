import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import MainLayout from './main-layout'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector, getSession, logoutClient } from '../redux/slices/authSlice'
import { AUTH_LOGIN } from '../assets/constants/route_path'
import { useCallback, useEffect } from 'react'
import { AUTH_TOKEN_KEY, USER_PROFILE_KEY } from '../config/constants'
import { getDefaultAuthenticatedRoute } from '../utils/app-utils'
import Loading from '../components/loading'
import { useLoading } from '../context/LoadingContext'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { jwtDecode } from 'jwt-decode'
import { toastError } from '../utils/toast'

Chart.register(CategoryScale)

export default function Layout() {
  const authState = useSelector(authSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleGetSession = useCallback(async () => {
    try {
      const sessionResp = await dispatch(getSession()).unwrap()
      console.log('sessionResp', sessionResp)
    } catch (error) {
      console.log('🚀 ~ : Layout ~ line 55 ~ redirectLoginPasswordless ~ error', error)
    }
  }, [dispatch])

  const handleLogout = useCallback(async () => {
    try {
      await dispatch(logoutClient()).unwrap()
      toastError('Phiên đăng nhập đã hết hạn!')
      navigate(AUTH_LOGIN)
    } catch (error) {
      console.log(error)

    }
  }, [dispatch, navigate])

  function isTokenExpired(): boolean {
    try {
      const token: string | null = localStorage.getItem(AUTH_TOKEN_KEY)
      if (token === null) return true
      const decodedToken = jwtDecode<{ exp: number }>(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      return true;
    }
  }

  useEffect(() => {
    if (!authState.isAuthenticated || isTokenExpired()) {
      handleLogout()
    } else {
      const cacheUser: any = localStorage?.getItem(USER_PROFILE_KEY)
      if (!cacheUser) {
        handleGetSession()
      }
    }
    if (location.pathname === '/' && authState.isAuthenticated) {
      navigate(getDefaultAuthenticatedRoute(authState?.account))
    }
  }, [location.pathname, authState?.account, authState?.isAuthenticated, navigate, handleGetSession, handleLogout])

  const { isLoading } = useLoading()

  return (
    <div id='wrapper'>
      <MainLayout>
        {isLoading && <Loading />}
        <div style={{ padding: '16px' }} className='w-100 p-3'>
          <Outlet />
        </div>
      </MainLayout>
    </div>
  )
}
