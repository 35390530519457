import { Button, Divider, Form, Input, InputNumber, List, Modal, Popconfirm, Select, Space, Table, TableProps } from "antd"
import { PlusOutlined, CloseOutlined } from "@ant-design/icons"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import { IPackage } from "../../models/package.modal"
import { useLoading } from "../../context/LoadingContext"
import { apiCreateListPartnerPackages, apiGetListPackages } from "../../api"
import { useSearchParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import defaultParams from "../../assets/constants/default_params"
import { formatCurrency } from "../../utils/string-utils"
import { partnerPackageTypeOptions } from "../../assets/constants/default_options"

export default function PackagePartnerCreate() {
    const [packages, setPackages] = useState<IPackage[]>([])
    const [listRemovePackages, setListRemovePackages] = useState<any>([])
    const [searchPackageKey, setSearchPackageKey] = useState<string>('')
    const [packageName, setPackageName] = useState<string>('')
    const [listAddPackages, setListAddPackages] = useState<any>([])
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [formCreate] = Form.useForm()
    const { setIsLoading } = useLoading()
    const [searchParams] = useSearchParams()
    const companyId = searchParams.get('id')
    const companyName = searchParams.get('name')
    const cpCode = searchParams.get('code')

    const fetchPackages = useCallback(async () => {
        try {
            const resPackages = await apiGetListPackages({ limit: defaultParams.MAX_LIMIT })
            if (resPackages.data != null) {
                setPackages(resPackages.data.packages)
            } else {
                setPackages([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchPackages()
    }, [fetchPackages])

    const handleAddPackage = (values: any) => {
        if (!packageName) {
            toastError('Vui lòng chọn gói cước!')
            return
        }

        try {
            setIsLoading(true)
            values.company_id = Number(companyId)
            values.packageName = packageName
            values.cpCode = cpCode
            setListAddPackages([...listAddPackages, values])
            const newPackages = packages.filter((pack: any) => {
                if (pack.id !== values.package_id) {
                    return true
                }
                setListRemovePackages([...listRemovePackages, pack])
                return false
            })
            setPackages(newPackages)
            toastSuccess('Đã thêm gói cước vào danh sách!')
            setPackageName('')
            formCreate.resetFields()
        } catch (error) {
            toastError('Vui lòng kiểm tra lại thông tin!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleRemovePackage = (item: any) => {
        const newListAddPackages = listAddPackages?.filter((pack: any) => {
            if (pack.package_id !== item.package_id) {
                return true
            }
            const newListPackages = listRemovePackages?.filter((packRm: any) => {
                if (packRm.id !== item.package_id) {
                    return true
                }
                setPackages([...packages, packRm])
                return false
            })
            setListRemovePackages(newListPackages)
            return false
        })
        setListAddPackages(newListAddPackages)
        toastError('Đã xoá gói cước khỏi danh sách!')
    }

    const packageColumns: TableProps<any>['columns'] = [
        {
            key: 'stt',
            title: 'STT',
            render: (_text, _record, index) => index + 1,
        },
        {
            key: 'packageName',
            title: 'Gói cước gốc',
            dataIndex: 'packageName',
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'code',
            title: 'Mã gói cước',
            dataIndex: 'code',
        },
        {
            key: 'type',
            title: 'Loại gói cước',
            dataIndex: 'type',
        },
        {
            key: 'price',
            title: 'Giá bán',
            dataIndex: 'price',
            render: (price) => formatCurrency(price),
        },
        {
            key: 'price_cashback',
            title: 'Giá trả lại',
            dataIndex: 'price_cashback',
            render: (price_cashback) => formatCurrency(price_cashback),
        },
        {
            key: 'action',
            render: (data) => (
                <Popconfirm
                    title="Xoá gói cước"
                    description="Xoá gói cước khỏi danh sách?"
                    onConfirm={() => handleRemovePackage(data)}
                    okText="Xác nhận"
                    cancelText="Huỷ bỏ"
                >
                    <Button type="primary" danger>Xoá</Button>
                </Popconfirm>
            )
        }
    ]

    const handleCreate = async () => {
        try {
            setIsLoading(true)
            const resp = await apiCreateListPartnerPackages({ partner_packages: listAddPackages })
            if (resp.status === 200) {
                toastSuccess(`Thêm thành công ${listAddPackages.length} gói cước mới!`)
                setListAddPackages([])
                setIsOpenModal(false)
            } else {
                toastError('Có lỗi xảy ra!')
            }
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <h2>Đối tác: {companyName}</h2>
            <div style={{ display: 'flex', gap: 20 }}>
                <div>
                    <Input value={searchPackageKey} onChange={e => setSearchPackageKey(e.target.value)} placeholder="Nhập tên gói cước" />
                    <div style={{ width: 200, height: 560, overflow: 'auto', marginTop: 10, paddingLeft: 10 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={packages?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                                .filter((item: any) => item.name?.toLowerCase().includes(searchPackageKey.toLowerCase()))}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <Button onClick={() => {
                                        setPackageName(item.name)
                                        formCreate.setFieldsValue({
                                            package_id: item.id,
                                            name: item.name,
                                            code: item.code,
                                            price: item.price,
                                            price_cashback: 0,
                                            description: item.description,
                                        })
                                    }} type="text">
                                        {item.name}
                                    </Button>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <h3 style={{ marginTop: 0 }}>Gói cước: {packageName}</h3>
                    <Form form={formCreate} onFinish={handleAddPackage} layout="vertical" autoComplete="off">
                        <Form.Item style={{ display: 'none' }} name='package_id' />
                        <div style={{ display: 'flex', gap: 20 }}>
                            <Form.Item style={{ flex: 1 }} name='name' label='Tên gói cước'
                                rules={[{ required: true, message: 'Vui lòng nhập tên gói cước!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} name='code' label='Mã gói cước'
                                rules={[{ required: true, message: 'Vui lòng nhập mã gói cước!' }]}>
                                <Input />
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', gap: 20 }}>
                            <Form.Item style={{ flex: 1 }} name='price' label='Giá bán'
                                rules={[{ required: true, message: 'Vui lòng nhập giá bán!' }]}>
                                <InputNumber formatter={value => formatCurrency(Number(value))} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} name='price_cashback' label='Giá trả lại'
                                rules={[{ required: true, message: 'Vui lòng nhập giá trả lại!' }]}>
                                <InputNumber formatter={value => formatCurrency(Number(value))} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} name='type' label='Loại gói cước'
                                rules={[{ required: true, message: 'Vui lòng chọn loại gói cước!' }]}>
                                <Select options={partnerPackageTypeOptions} placeholder="Chọn loại gói cước" />
                            </Form.Item>
                        </div>
                        <Form.Item name='description' label='Mô tả'>
                            <Input.TextArea />
                        </Form.Item>
                        <div style={{ textAlign: 'end' }}>
                            <Space>
                                <Button htmlType="submit" icon={<PlusOutlined />}
                                    style={{ background: '#008965', color: 'white' }}>
                                    <b>Thêm</b>
                                </Button>
                                <Button onClick={() => setPackageName('')}
                                    htmlType="reset" icon={<CloseOutlined />}
                                    style={{ background: '#c93c3c', color: 'white' }}>
                                    <b>Xoá</b>
                                </Button>
                            </Space>
                        </div>
                    </Form>
                    <Divider />
                    <div style={{ marginTop: 20, overflow: 'auto' }}>
                        <div style={{ maxHeight: 360, overflow: 'auto' }}>
                            <Table
                                rowKey={(_, index: any) => index}
                                style={{ overflow: 'auto' }}
                                columns={packageColumns}
                                dataSource={listAddPackages}
                                pagination={false}
                                footer={() => (
                                    <div>Tổng {listAddPackages.length || '0'} gói cước</div>
                                )}
                            />
                        </div>
                        <div style={{ textAlign: 'end', marginTop: 20 }}>
                            <Button
                                disabled={listAddPackages.length === 0}
                                onClick={() => setIsOpenModal(true)}
                                type="primary"
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                    <Modal title='Thêm gói cước' open={isOpenModal}
                        onCancel={() => setIsOpenModal(false)}
                        footer={[
                            <Button onClick={handleCreate} type='primary'>
                                Thêm
                            </Button>,
                        ]}>
                        Bạn có chắc chắn muốn thêm các gói cước?
                    </Modal>
                </div>
            </div>
        </div>
    )
}
