import { Form, Input } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiUpdateRole } from "../../api"
import { useLocation, useNavigate } from "react-router-dom"
import { IFormValueRole, IRole } from "../../models/role.model"
import { ROLE } from "../../assets/constants/route_path"
import { useEffect } from "react"
import { convertListStringToPermission } from "../../utils/perm-utils"
import { ListFormCheckbox } from "./FormCheckbox"
import { permissionOptions } from "../../assets/constants/default_options"

export default function RoleUpdate() {
    const [formUpdate] = Form.useForm()
    const { setIsLoading } = useLoading()
    const location = useLocation()
    const navigate = useNavigate()
    const role = location.state as IRole

    useEffect(() => {
        if (!role) {
            navigate(role)
        }
    }, [role, navigate])

    const handleUpdate = async ({ name, description, ...groups }: IFormValueRole) => {
        try {
            setIsLoading(true)
            const perms = Object
                .entries(groups)
                .map(([_, value]) => convertListStringToPermission(value))
                .flat(Infinity) as []
            const resp = await apiUpdateRole({
                id: Number(role.id),
                name,
                description,
                permissions: perms,
            })
            if (resp.status === 200) {
                toastSuccess('Cập nhật phân quyền thành công!')
                formUpdate.resetFields()
                navigate(ROLE)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Form form={formUpdate} onFinish={handleUpdate} layout="vertical">
                <Form.Item name='name' label='Tên phân quyền' initialValue={role.name}
                    rules={[{ required: true, message: 'Vui lòng nhập tên phân quyền!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='description' label='Mô tả' initialValue={role.description}>
                    <Input />
                </Form.Item>
                <ListFormCheckbox options={permissionOptions} initValues={role.permissions} />
                <ButtonSubmitBack />
            </Form>
        </div>
    )
}
