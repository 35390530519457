import { useCallback, useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Form, Input, Modal, Select, TableProps } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import moment from "moment"
import dayjs from 'dayjs'
import CustomTable from '../../components/table'
import defaultParams, { FORMAT_DATE } from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListSimPassports } from '../../api'
import FormSearch from '../../components/form'
import { Link } from 'react-router-dom'
import { CUSTOMER, DETAIL } from '../../assets/constants/route_path'
import { typeCustomerOptions } from '../../assets/constants/default_options'
import { toastSuccess } from '../../utils/toast'

export default function CustomerPage() {
    const [customers, setCustomers] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        full_name: '',
        nation: '',
        passport_number: '',
        email: '',
    })
    const [modalCreate, setModalCreate] = useState(false)

    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'Mã khách hàng',
            dataIndex: 'id',
            render: id => `customer-${id}`
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự tạo',
            dataIndex: 'id',
            render: id => `admin-${id}`
        },
        {
            key: 'full_name',
            title: 'Họ và tên',
            dataIndex: 'full_name',
        },
        {
            key: 'date_of_birth',
            title: 'Ngày sinh',
            dataIndex: 'date_of_birth',
        },
        {
            key: 'phone_number',
            title: 'Số điện thoại',
            dataIndex: 'id',
            render: id => `902641444${id}`
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
        },
        {
            key: 'nation',
            title: 'Quốc tịch',
            dataIndex: 'nation',
        },
        {
            key: 'number_identity',
            title: 'Số giấy tờ',
            dataIndex: 'passport_number',
        },
        {
            key: 'type_identity',
            title: 'Loại giấy tờ',
            dataIndex: 'type_identity',
            render: () => 'Hộ chiếu'
        },
        {
            key: 'expire_date',
            title: 'Ngày hết hạn',
            dataIndex: 'expire_date',
        },
        {
            key: 'modify',
            title: 'Ngày tạo',
            dataIndex: 'modify',
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'id',
            render: id => (
                <Link to={CUSTOMER + DETAIL + `?id=${id}`}>
                    <Button style={{ background: '#289e45', color: 'white' }} icon={<EyeOutlined />}>
                        Chi tiết
                    </Button>
                </Link>
            )
        }
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resPackages = await apiGetListSimPassports({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                full_name: search.full_name,
                nation: search.nation,
                passport_number: search.passport_number,
                email: search.email,
            })
            if (resPackages.data != null) {
                setCustomers(resPackages.data)
            } else {
                setCustomers({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ full_name, nation, passport_number, email }: any) => {
        if (full_name || nation || passport_number || email) {
            setSearch({ full_name, nation, passport_number, email })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            full_name: '',
            nation: '',
            passport_number: '',
            email: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleCreate = async () => {
        toastSuccess('Thêm khách hàng thành công!')
        setModalCreate(false)
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='ownerBy' label='Nhân sự tạo'>
                    <Input />
                </Form.Item>
                <Form.Item name='customer_id' label='Mã khách hàng'>
                    <Input />
                </Form.Item>
                <Form.Item name='full_name' label='Tên khách hàng'>
                    <Input />
                </Form.Item>
                <Form.Item name='phone_numer' label='Số điện thoại'>
                    <Input />
                </Form.Item>
                <Form.Item name='email' label='Email'>
                    <Input />
                </Form.Item>
            </FormSearch>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Button onClick={() => setModalCreate(true)} type='primary'>
                    Thêm khách hàng
                </Button>
            </div>
            <CustomTable
                namePage='sim passport'
                columns={columns}
                dataSource={customers.sim_passports}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={customers.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Thêm khách hàng mới'
                open={modalCreate}
                onCancel={() => setModalCreate(false)}
                footer={[
                    <Button onClick={handleCreate} type="primary">Thêm</Button>
                ]}
            >
                <Divider />
                <Form labelCol={{ span: 6 }} labelAlign="left" autoComplete='off'>
                    <Form.Item name='full_name' label='Họ và tên'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='phone_number' label='Số điện thoại'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label='Email'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='number_identity' label='Số giấy tờ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='type_identity' label='Loại giấy tờ' initialValue={typeCustomerOptions[0].value}>
                        <Select options={typeCustomerOptions} />
                    </Form.Item>
                    <Form.Item name='expire_date' label='Ngày hết hạn'>
                        <DatePicker
                            style={{ width: '100%' }}
                            allowClear={false}
                            format={FORMAT_DATE}
                            defaultValue={dayjs(moment().format(FORMAT_DATE), FORMAT_DATE)}
                        />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nation' label='Quốc gia'>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
