import { Form, Input, Select } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiCreateTransaction, apiGetListCompanies, apiGetListPackages } from "../../api"
import { useCallback, useEffect, useState } from "react"
import { formatCurrency } from "../../utils/string-utils"
import { IPackage } from "../../models/package.modal"
import { ICompany } from "../../models/company.modal"
import defaultParams from "../../assets/constants/default_params"
import { filterOptionByLabel } from "../../utils/filter-utils"
import { ICreateTransaction } from "../../models/transaction.modal"

export default function TransactionCreate() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [packageOptions, setPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const [formCreate] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchCompanies = useCallback(async () => {
        try {
            setIsLoading(true)
            const resPackages = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resPackages.data != null) {
                const options = resPackages.data.companies.map((item: ICompany) => ({
                    label: `${item.id} - ${item.name}`,
                    value: item.id
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    const fetchPackages = useCallback(async () => {
        try {
            setIsLoading(true)
            const resPackages = await apiGetListPackages({ limit: defaultParams.MAX_LIMIT })
            if (resPackages.data != null) {
                const options = resPackages.data.packages.map((item: IPackage) => ({
                    label: `${item.id} - ${item.name} - ${formatCurrency(Number(item.price))}/${item.numberDay} ngày`,
                    value: item.id
                }))
                setPackageOptions(options)
            } else {
                setPackageOptions([])
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchCompanies()
        fetchPackages()
    }, [fetchCompanies, fetchPackages])

    const handleCreate = async (values: ICreateTransaction) => {
        try {
            setIsLoading(true)
            const resp = await apiCreateTransaction(values)
            if (resp.status === 200) {
                toastSuccess('Thêm giao dịch mới thành công!')
                formCreate.resetFields()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Form form={formCreate} onFinish={handleCreate} layout="vertical">
                <Form.Item name='customer' label='Điện thoại'
                    rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='company_id' label='Công ty'
                    rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='packages_id' label='Gói cước'
                    rules={[{ required: true, message: 'Vui lòng chọn gói cước!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn gói cước"
                        options={packageOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='msisdn' label='Msisdn'
                    rules={[{ required: true, message: 'Vui lòng nhập msisdn!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='serialSim' label='SerialSim'
                    rules={[{ required: true, message: 'Vui lòng nhập serialSim!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='description' label='Mô tả'>
                    <Input />
                </Form.Item>
                <ButtonSubmitBack />
            </Form>
        </div>
    )
}
