import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import NAME_SPACES from './helpers/nameSpaces'
let isDebug = true
if (process.env.NODE_ENV !== 'development') {
  isDebug = false
}
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: isDebug,
    lng: 'vi',
    fallbackLng: 'vi',
    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
    },
  })
i18n.loadNamespaces([NAME_SPACES.GLOBAL])
export default i18n
