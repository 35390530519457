import { PROFILE } from '../../../assets/constants/route_path'
import logoImg from '../../../assets/images/logo-otek.png'
import { Button, Form, type FormProps, Input, Row, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getSession, login } from '../../../redux/slices/authSlice'
import { toastError } from '../../../utils/toast'
import { useLoading } from '../../../context/LoadingContext'
import md5 from 'blueimp-md5'
import { ADMIN_USERNAME, SECRET_KEY } from '../../../config/constants'

type FieldType = {
    username?: string
    password?: string
}

const LoginComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { setIsLoading } = useLoading()

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        try {
            const { username, password } = values
            if (username && password) {
                setIsLoading(true)
                let hashPassword = password
                if (username !== ADMIN_USERNAME) {
                    hashPassword = md5(password, SECRET_KEY)
                }
                const resp = await dispatch(login({ username, password: hashPassword })).unwrap()
                console.log('resp', resp)
                const sessionResp = await dispatch(getSession()).unwrap()
                console.log('sessionResp', sessionResp)
                setIsLoading(false)
                navigate(PROFILE)
            }
        }
        catch (error: any) {
            setIsLoading(false)
            console.log(error)
            const message = error ? 'Tên đăng nhập hoặc mật khẩu không chính xác!' : 'Có lỗi xảy ra!'
            toastError(message)
        }
    }

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <div
            style={{
                padding: 20,
                backgroundColor: '#FFF',
                borderRadius: 10,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
            }}>
            <Form
                name='basic'
                labelCol={{ span: 20 }}
                wrapperCol={{ span: 25 }}
                style={{ minWidth: 300 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
                layout='vertical'
            >
                <Row justify='center' style={{ marginBottom: 20 }}>
                    <Image
                        width={100}
                        src={logoImg}
                        preview={false}
                    />
                </Row>
                <Form.Item<FieldType>
                    label={'Tên đăng nhập:'}
                    name='username'
                    rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label={'Mật khẩu:'}
                    name='password'
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Row justify='center' style={{ marginTop: 30 }}>
                    <Button type='primary' htmlType='submit' style={{ background: '#fe1433' }}>
                        Đăng nhập
                    </Button>
                </Row>
            </Form>
        </div>
    )
}

export default LoginComponent
