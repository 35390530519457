import Background from '../../../components/background'
import LoginComponent from './LoginComponent'
import Loading from '../../../components/loading'
import { useLoading } from '../../../context/LoadingContext'

const LoginPage = () => {
    const { isLoading } = useLoading()
    return <>
        <Background />
        {isLoading && <Loading />}
        <LoginComponent />
    </>
}

export default LoginPage