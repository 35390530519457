import { Form, Input, Select } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiGetListCompanies, apiGetListRoles, apiUpdateUserPartner } from "../../api"
import { useLocation, useNavigate } from "react-router-dom"
import { COMPANY, USER_PARTNER } from "../../assets/constants/route_path"
import { useCallback, useEffect, useState } from "react"
import defaultParams from "../../assets/constants/default_params"
import { IRole } from "../../models/role.model"
import { filterOptionByLabel } from "../../utils/filter-utils"
import { ICompany } from "../../models/company.modal"

export default function UserPartnerUpdate() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [roleOptions, setRoleOptions] = useState<{ label: string, value: Number }[]>([])
    const [formUpdate] = Form.useForm()
    const { setIsLoading } = useLoading()
    const location = useLocation()
    const navigate = useNavigate()
    const user = location.state

    const fetchOptions = useCallback(async () => {
        try {
            setIsLoading(true)
            const [respCompany, respRole] = await Promise.all([
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT }),
                apiGetListRoles({ limit: defaultParams.MAX_LIMIT }),
            ])
            if (respRole.data?.roles === null && respCompany.data?.companies === null) {
                return
            }
            const compOptions = respCompany.data.companies.map((item: ICompany) => ({
                label: `${item.name} (${item.code})`,
                value: item.code
            }))
            setCompanyOptions(compOptions)
            const listRoles = respRole.data.roles.filter((item: IRole) => item.name !== 'Super Admin')
            const roleOptions = listRoles.map((item: IRole) => ({
                label: item.name,
                value: item.id
            }))
            setRoleOptions(roleOptions)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading])

    const handleUpdate = async (data: any) => {
        try {
            setIsLoading(true)
            const resp = await apiUpdateUserPartner({ id: user.id, ...data })
            if (resp.status === 200) {
                toastSuccess('Cập nhật quản trị viên thành công!')
                formUpdate.resetFields()
                navigate(COMPANY + USER_PARTNER)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error: any) {
            const err = error?._data?.message || 'Có lỗi xảy ra!'
            toastError(err)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user) {
            navigate(user)
        }
        fetchOptions()
    }, [user, navigate, fetchOptions])

    return (
        <div>
            <ButtonBack />
            <Form form={formUpdate} onFinish={handleUpdate} layout="vertical" autoComplete="off">
                <Form.Item name='username' label='Tên đăng nhập' initialValue={user.username}
                    rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='cpCode' label='Mã công ty' initialValue={user.cpCode}
                    rules={[{ required: true, message: 'Vui lòng nhập mã công ty!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='role_id' label='Vai trò' initialValue={user.role?.id}
                    rules={[{ required: true, message: 'Vui lòng chọn vai trò!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn vai trò"
                        options={roleOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <ButtonSubmitBack />
            </Form>
        </div>
    )
}
