const AUTH = 'auth'
const GLOBAL = "global"
const USER = 'user'

const NAME_SPACES = {
    AUTH,
    GLOBAL,
    USER
}

export default NAME_SPACES