import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, Modal, Select, Space, TableProps, Tag, Upload, message } from 'antd'
import { MoneyCollectOutlined, UploadOutlined, EyeOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { toastError, toastSuccess } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import { formatCurrency } from '../../utils/string-utils'
import { apiGetListOrderTkgDashboard, apiTkgDashboardByOrderId, apiTkgDashboardByPhone, apiUploadOrderTkgDashboardByExcel, apiGetListUsers, apiGetListPackagesTkgDashboard } from '../../api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { DETAIL_TRANSACTION, ROOT_ACCOUNT } from '../../assets/constants/route_path'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { orderStatusOptions, packageTkgOptions } from '../../assets/constants/default_options'
import { IUser } from '../../models/user.model'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'

export default function RootAccountOrder() {
    const [packTkgOptions, setPackTkgOptions] = useState(packageTkgOptions)
    const [orderTkg, setOrderTkg] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        status: '',
    })
    const [modalStatus, setModalStatus] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalTkgPhone, setModalTkgPhone] = useState<boolean>(false)
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [modalUpload, setModalUpload] = useState<boolean>(false)
    const [fileExcel, setFileExcel] = useState<any>()
    const [fileList, setFileList] = useState([])
    const [descExcel, setDescExcel] = useState<any>()
    const [dataPack, setDataPack] = useState<string>()

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'orderId',
            title: 'Mã đơn hàng',
            dataIndex: 'orderId',
            render: orderId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {orderId}
                </span>
            ),
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự tạo đơn',
            dataIndex: 'ownerBy',
        },
        {
            key: 'description',
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            key: 'totalPhoneNumber',
            title: 'Tổng số điện thoại',
            dataIndex: 'totalPhoneNumber',
        },
        {
            key: 'totalPrice',
            title: 'Tổng giá',
            dataIndex: 'totalPrice',
            render: (totalPrice) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(totalPrice)}
                </span>
            ),
        },
        {
            key: 'totalSuccess',
            title: 'Tổng thành công',
            dataIndex: 'totalSuccess',
        },
        {
            key: 'totalFail',
            title: 'Tổng thất bại',
            dataIndex: 'totalFail',
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status)
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    {account.canGetListTransactionTkgDashboardByOrderId && (
                        <Link to={ROOT_ACCOUNT + DETAIL_TRANSACTION + `?orderId=${data.orderId}`}>
                            <Button
                                icon={<EyeOutlined />}
                                style={{ background: '#289e45', color: 'white' }}
                            >
                                Chi tiết
                            </Button>
                        </Link>
                    )}
                    {account.canTkgByOrderId && (
                        <Button
                            disabled={data.status !== 'CREATED'}
                            onClick={() => setModalStatus({ data, isOpen: true })}
                            icon={<MoneyCollectOutlined />}
                            type='primary'
                        >
                            Nạp gói cước
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === 'COMPLETED') return <Tag style={style} color="#9b9b05">Hoàn thành</Tag>
        if (state === 'CANCELLED') return <Tag style={style} color="#c93c3c">Đã huỷ bỏ</Tag>
        if (state === 'CREATED') return <Tag style={style} color="#2faac6">Đã tạo</Tag>
        if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
        return <Tag style={style} color="#ff6f00">Đang nạp excel</Tag>
    }

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const respOrder = await apiGetListOrderTkgDashboard({
                page: currentPage,
                size: pageSize,
                status: search.status,
            })
            if (respOrder.data === null) {
                setOrderTkg({})
                return
            }
            setOrderTkg(respOrder.data)

            const respUsers = await apiGetListUsers({ limit: defaultParams.MAX_LIMIT })
            if (respUsers.data === null) {
                return
            }

            const resp = { total: respOrder.data.total, orders: [] as any }
            const listOrders = respOrder.data.orders
            const listUsers = respUsers.data.users
            for (let i = 0; i < listOrders.length; i++) {
                const order = listOrders[i]
                const userInfo = listUsers.find((user: IUser) => user.id === order.ownerBy)
                resp.orders.push({ ...order, ownerBy: userInfo?.full_name || userInfo?.username })
            }
            setOrderTkg(resp)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const fetchPackOptions = useCallback(async () => {
        try {
            const resp = await apiGetListPackagesTkgDashboard()
            const listPackages = resp.data?.partner_packages
            if (listPackages) {
                const listOptions = listPackages.map((item: any) => {
                    return { value: item.name, label: `${item.name} - ${formatCurrency(Number(item.price))}` }
                })
                setPackTkgOptions(listOptions)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchData()
        fetchPackOptions()
    }, [fetchData, fetchPackOptions])

    const handleSearch = ({ status }: any) => {
        if (status) {
            setSearch({ status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleTkgOrderId = async () => {
        try {
            setIsLoading(true)
            const resp = await apiTkgDashboardByOrderId({ orderId: modalStatus.data.id })
            if (resp.status === 200) {
                toastSuccess(resp.data.message)
                fetchData()
                handleCloseModalTkgOrderId()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalTkgOrderId = () => {
        setModalStatus({ data: null, isOpen: false })
        setDataPack(undefined)
    }

    const handleTkgPhone = async () => {
        if (!phoneNumber || !dataPack) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        const regex = /^(0|84)?\d{9,10}$/
        if (!regex.test(phoneNumber.replaceAll(' ', ''))) {
            toastError('Vui lòng nhập số điện thoại hợp lệ!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiTkgDashboardByPhone({ phoneNumber, dataPack })
            if (resp.status === 200) {
                setTimeout(() => {
                    toastSuccess(resp.data.message)
                    fetchData()
                    handleCloseModalTkgPhone()
                    setIsLoading(false)
                }, 5000)
            } else {
                toastError('Có lỗi xảy ra!')
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalTkgPhone = () => {
        setModalTkgPhone(false)
        setPhoneNumber('')
        setDataPack(undefined)
    }

    const handleUploadFile = async () => {
        if (!fileExcel || !descExcel) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("file", fileExcel.file)
            const resp = await apiUploadOrderTkgDashboardByExcel(formData, descExcel)
            if (resp.status === 200) {
                toastSuccess('nạp gói cước đang thực hiện! Vui lòng kiểm tra kết quả sau ít phút')
                fetchData()
                handleCloseModalUploadFile()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }
    const handleCloseModalUploadFile = () => {
        setModalUpload(false)
        setFileExcel({})
        setDescExcel('')
    }
    const checkUploadFile = (file: any) => {
        const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
        if (!isExcel) {
            message.error('Vui lòng upload file dạng excel!')
            return Upload.LIST_IGNORE
        }
        return false
    }
    const handleChangeFile = (value: any) => {
        setFileList(value.fileList)
        setFileExcel(value)
    }

    return (
        <div>
            <Form form={formSearch} onFinish={handleSearch}>
                <Space style={{ display: 'flex', alignItems: 'start' }}>
                    <Form.Item name='status' label='Trạng thái'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn trạng thái"
                            options={orderStatusOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </Space>
            </Form>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Space>
                    {account.canTkgByPhone && (
                        <Button onClick={() => setModalTkgPhone(true)} type='dashed'>Nạp gói cước</Button>
                    )}
                    {account.canUploadOrderTkgByExcel && (
                        <Button onClick={() => setModalUpload(true)} type='primary'>Import đơn hàng (File Excel)</Button>
                    )}
                </Space>
            </div>
            <CustomTable
                namePage='đơn hàng'
                columns={columns}
                dataSource={orderTkg.orders}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={orderTkg.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Nạp gói cước' open={modalStatus.isOpen}
                onCancel={handleCloseModalTkgOrderId}
                footer={[
                    <Button onClick={handleTkgOrderId}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTkgOrderId}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nạp gói cước cho đơn hàng <b>{modalStatus.data?.orderId}</b></p>
                <Divider />
            </Modal>

            <Modal title='Nạp gói cước' open={modalTkgPhone}
                onCancel={handleCloseModalTkgPhone}
                footer={[
                    <Button onClick={handleTkgPhone}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTkgPhone}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nhập số điện thoại</p>
                <Input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} style={{ width: '100%' }} />
                <p>Chọn gói cước</p>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="Chọn gói cước"
                    options={packTkgOptions}
                    value={dataPack}
                    onChange={value => setDataPack(value)}
                    filterOption={filterOptionByLabel}
                />
                <Divider />
            </Modal>

            <Modal title='Upload' open={modalUpload}
                onCancel={handleCloseModalUploadFile}
                footer={[
                    <Button onClick={handleUploadFile}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalUploadFile}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Đăng tải File Excel</p>
                <Upload
                    maxCount={1}
                    fileList={fileList}
                    onChange={handleChangeFile}
                    beforeUpload={checkUploadFile}
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                <p>Nhập mô tả</p>
                <Input value={descExcel} onChange={e => setDescExcel(e.target.value)} />
                <Divider />
                <p><b>Ghi chú</b></p>
                <p>Định dạng: Số điện thoại và mã gói cước</p>
                <p>Tên sheet: Sheet1</p>
                <p>Tối đa: 1000 số điện thoại</p>
                <Divider />
            </Modal>
        </div>
    )
}
