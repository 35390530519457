import { Form, Input, Select } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import { ICreateCompany } from "../../models/company.modal"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiCreateCompany, apiGetListPackages } from "../../api"
import { useCallback, useEffect, useState } from "react"
import { IPackage } from "../../models/package.modal"
import { formatCurrency } from "../../utils/string-utils"
import defaultParams from "../../assets/constants/default_params"
import { filterOptionByLabel } from "../../utils/filter-utils"

export default function CompanyCreate() {
    const [packageOptions, setPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const [formCreate] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchPackages = useCallback(async () => {
        try {
            setIsLoading(true)
            const resPackages = await apiGetListPackages({ limit: defaultParams.MAX_LIMIT })
            if (resPackages.data != null) {
                const options = resPackages.data.packages.map((item: IPackage) => ({
                    label: `${item.id} - ${item.name} - ${formatCurrency(Number(item.price))}/${item.numberDay} ngày`,
                    value: item.id
                }))
                setPackageOptions(options)
            } else {
                setPackageOptions([])
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchPackages()
    }, [fetchPackages])

    const handleCreate = async (values: ICreateCompany) => {
        try {
            setIsLoading(true)
            const resp = await apiCreateCompany(values)
            if (resp.status === 200) {
                toastSuccess('Thêm công ty mới thành công!')
                formCreate.resetFields()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Form form={formCreate} onFinish={handleCreate} layout="vertical">
                <Form.Item name='name' label='Tên công ty'
                    rules={[{ required: true, message: 'Vui lòng nhập tên công ty!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='code' label='Mã công ty'
                    rules={[{ required: true, message: 'Vui lòng nhập mã công ty!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='templateId' label='ID Template'>
                    <Input />
                </Form.Item>
                <Form.Item name='packageNewId' label='Gói cước gợi ý'>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn gói cước"
                        options={packageOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='description' label='Mô tả'>
                    <Input />
                </Form.Item>
                <ButtonSubmitBack />
            </Form>
        </div>
    )
}
