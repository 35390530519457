import { Button, Form, Input, Space } from 'antd'
import { toastError, toastSuccess } from '../../utils/toast'
import md5 from 'blueimp-md5'
import { apiChangePassword } from "../../api"
import { useLoading } from "../../context/LoadingContext"
import { ADMIN_USERNAME, SECRET_KEY } from '../../config/constants'

export default function ChangePassword({ admin }: any) {
    const [formChangePassword] = Form.useForm()
    const { setIsLoading } = useLoading()

    const handleChangePassword = async (data: any) => {
        const { old_password, password, re_password } = data
        if (password !== re_password) {
            toastError('Nhắc lại mật khẩu không chính xác!')
            return
        }

        try {
            setIsLoading(true)
            let hashOldPassword = md5(old_password, SECRET_KEY)
            let hashNewPassword = md5(password, SECRET_KEY)
            if (admin.username === ADMIN_USERNAME) {
                hashOldPassword = old_password
                hashNewPassword = password
            }

            const resp = await apiChangePassword({
                id: admin.id,
                old_password: hashOldPassword,
                new_password: hashNewPassword,
            })

            if (resp.status === 200) {
                toastSuccess('Đổi mật khẩu thành công!')
                formChangePassword.resetFields()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            const message = error ? 'Mật khẩu không chính xác!' : 'Có lỗi xảy ra!'
            toastError(message)
            setIsLoading(false)
        }
    }

    return (
        <Form
            form={formChangePassword}
            onFinish={handleChangePassword}
            labelAlign='left'
            labelCol={{ span: 7 }}>
            <Form.Item label='Mật khẩu cũ' name='old_password'
                rules={[{ required: true, min: 6, message: 'Vui lòng nhập mật khẩu cũ và ít nhất 6 ký tự!' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item label='Mật khẩu mới' name='password'
                rules={[{ required: true, min: 6, message: 'Vui lòng nhập mật khẩu mới và ít nhất 6 ký tự!' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item label='Nhắc lại mật khẩu' name='re_password'
                rules={[{ required: true, min: 6, message: 'Vui lòng nhập nhắc lại mật khẩu và ít nhất 6 ký tự!' }]}>
                <Input.Password />
            </Form.Item>
            <Space>
                <Button key='submit' type='primary' htmlType='submit'>
                    Đổi mật khẩu
                </Button>
                <Button type='dashed' htmlType='reset'>
                    Huỷ bỏ
                </Button>
            </Space>
        </Form>
    )
}
