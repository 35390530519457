import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Input, Select, TableProps } from 'antd'
import CustomTable from '../../components/table'
import ActionViewRenew from './ActionViewRenew'
import defaultParams from '../../assets/constants/default_params'
import { apiGetListCompanies, apiGetListPackages, apiGetListTelegrams, apiGetListTransactions } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { ITransaction, ITransactions } from '../../models/transaction.modal'
import ModalView from './ModalView'
import ModalRenew from './ModalRenew'
import { IPackage } from '../../models/package.modal'
import { formatCurrency } from '../../utils/string-utils'
import { ITelegrams } from '../../models/telegram.modal'
import FormSearch from '../../components/form'
import { Link } from 'react-router-dom'
import { CREATE, TRANSACTION } from '../../assets/constants/route_path'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { ICompany } from '../../models/company.modal'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'

export default function Transaction() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [packageOptions, setPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const [telegrams, setTelegrams] = useState<ITelegrams>({})
    const [transactions, setTransactions] = useState<ITransactions>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        customer: '',
        msisdn: '',
        serialSim: '',
        company_id: 0,
        package_id: 0,
    })
    const [modalView, setModalView] = useState<{ data: ITransaction | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalRenew, setModaRenew] = useState<{ data: ITransaction | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'msisdn',
            title: 'Msisdn',
            dataIndex: 'msisdn',
        },
        {
            key: 'serialSim',
            title: 'SerialSim',
            dataIndex: 'serialSim',
        },
        {
            key: 'customer',
            title: 'Điện thoại',
            dataIndex: 'customer',
        },
        {
            key: 'startDate',
            title: 'Ngày bắt đầu',
            dataIndex: 'startDate',
        },
        {
            key: 'endDate',
            title: 'Ngày kết thúc',
            dataIndex: 'endDate',
        },
        {
            key: 'company',
            title: 'Công ty',
            render: (_, data: ITransaction) => data.company?.name || Number(data?.company_id),
        },
        {
            key: 'package',
            title: 'Gói cước',
            render: (_, data: ITransaction) => data.package?.name || Number(data?.package_id),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: ITransaction) => (
                <ActionViewRenew
                    canRenew={account.canRenewTransaction}
                    hanldeView={() => setModalView({ data, isOpen: true })}
                    hanldeRenew={() => setModaRenew({ data, isOpen: true })}
                />
            ),
        },
    ]

    const fetchTransactions = useCallback(async () => {
        try {
            setIsLoading(true)
            const resTransactions = await apiGetListTransactions({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                customer: search.customer,
                msisdn: search.msisdn,
                serialSim: search.serialSim,
                company_id: search.company_id,
                package_id: search.package_id,
            })
            if (resTransactions.data === null) {
                setTransactions({})
                return
            }
            setTransactions(resTransactions.data)
            handleMappingTransactions(resTransactions.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const handleMappingTransactions = async (respTran: any) => {
        try {
            const [respPackage, respCompany] = await Promise.all([
                apiGetListPackages({ limit: defaultParams.MAX_LIMIT }),
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            ])
            if (respPackage.data === null && respCompany.data === null) {
                return
            }
            const resp = { count: respTran.count, transactions: [] } as ITransactions
            const listTransactions = respTran.transactions
            const listPackages = respPackage.data.packages
            const listCompanies = respCompany.data.companies
            for (let i = 0; i < listTransactions.length; i++) {
                const transactionInfo = listTransactions[i]
                const packInfo = listPackages.find((pack: IPackage) => pack.id === transactionInfo.package_id)
                const companyInfo = listCompanies.find((company: ICompany) => company.id === transactionInfo.company_id)
                resp.transactions?.push({
                    ...transactionInfo,
                    package: packInfo || { id: transactionInfo.package_id },
                    company: companyInfo || { id: transactionInfo.company_id },
                })
            }
            setTransactions(resp)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchOptions = useCallback(async () => {
        try {
            const [respPackage, respCompany] = await Promise.all([
                apiGetListPackages({ limit: defaultParams.MAX_LIMIT }),
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            ])
            if (respPackage.data?.packages === null && respCompany.data?.companies === null) {
                return
            }

            const packOptions = respPackage.data.packages.map((item: IPackage) => ({
                label: `${item.id} - ${item.name} - ${formatCurrency(Number(item.price))}/${item.numberDay} ngày`,
                value: item.id
            }))
            setPackageOptions(packOptions)
            const compOptions = respCompany.data.companies.map((item: ICompany) => ({
                label: `${item.id} - ${item.name}`,
                value: item.id
            }))
            setCompanyOptions(compOptions)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchTelegrams = useCallback(async () => {
        try {
            const resTelegrams = await apiGetListTelegrams({ limit: defaultParams.MAX_LIMIT })
            if (resTelegrams.data === null) {
                setTelegrams({})
                return
            }
            setTelegrams(resTelegrams.data)
            handleMappingTelegrams(resTelegrams.data)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const handleMappingTelegrams = async (respTele: any) => {
        try {
            const [respPackage, respCompany] = await Promise.all([
                apiGetListPackages({ limit: defaultParams.MAX_LIMIT }),
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            ])
            if (respPackage.data === null && respCompany.data === null) {
                return
            }

            const resp = { count: respTele.count, telegrams: [] } as ITelegrams
            const listTelegrams = respTele.telegrams
            const listPackages = respPackage.data.packages
            const listCompanies = respCompany.data.companies
            for (let i = 0; i < listTelegrams.length; i++) {
                const telegramInfo = listTelegrams[i]
                const packInfo = listPackages.find((pack: IPackage) => pack.id === telegramInfo.package_id)
                const companyInfo = listCompanies.find((company: ICompany) => company.id === telegramInfo.company_id)
                resp.telegrams?.push({
                    ...telegramInfo,
                    package: packInfo || { id: telegramInfo.package_id },
                    company: companyInfo || { id: telegramInfo.company_id },
                })
            }
            setTelegrams(resp)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchTransactions()
        fetchOptions()
        fetchTelegrams()
    }, [fetchTransactions, fetchOptions, fetchTelegrams])

    const handleSearch = ({ customer, msisdn, serialSim, company_id, package_id }: any) => {
        if (customer || msisdn || serialSim || company_id || package_id) {
            setSearch({ customer, msisdn, serialSim, company_id, package_id })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            customer: '',
            msisdn: '',
            serialSim: '',
            company_id: 0,
            package_id: 0,
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='msisdn' label='Msisdn'>
                    <Input />
                </Form.Item>
                <Form.Item name='serialSim' label='SerialSim'>
                    <Input />
                </Form.Item>
                <Form.Item name='customer' label='Điện thoại'>
                    <Input />
                </Form.Item>
                <Form.Item name='company_id' label='Công ty'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='package_id' label='Gói cước'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn gói cước"
                        options={packageOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
            </FormSearch>
            {account.canAddTransaction && (
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Link to={TRANSACTION + CREATE}>
                        <Button type='primary'>Thêm giao dịch</Button>
                    </Link>
                </div>
            )}
            <CustomTable
                namePage='giao dịch'
                columns={columns}
                dataSource={transactions.transactions?.sort((a: ITransaction, b: ITransaction) => Number(a.id) - Number(b.id))}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={transactions.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <ModalView
                modalView={modalView}
                setModalView={setModalView}
            />
            <ModalRenew
                modalRenew={modalRenew}
                setModaRenew={setModaRenew}
                fetchData={fetchTransactions}
                packageOptions={packageOptions}
                telegrams={telegrams}
            />
        </div>
    )
}
