import { Key, useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Modal, Row, Space, TableProps, Tag, Typography } from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { apiDeleteUser, apiGetListUsers } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { IPermission } from '../../models/role.model'
import { Link } from 'react-router-dom'
import { CREATE, UPDATE, USER } from '../../assets/constants/route_path'
import { toastError, toastSuccess } from '../../utils/toast'
import ActionEditDelete from '../../components/dropdown/ActionEditDelete'
import { IUser, IUsers } from '../../models/user.model'
import { permissionOptions } from '../../assets/constants/default_options'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { ADMIN_USERNAME } from '../../config/constants'

export default function User() {
    const [users, setUsers] = useState<IUsers>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [modalDelete, setModalDelete] = useState<{ data: IUser | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalPermissions, setModalPermissions] = useState<{ data: IUser | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'username',
            title: 'Tên đăng nhập',
            dataIndex: 'username',
        },
        {
            key: 'full_name',
            title: 'Tên quản trị viên',
            dataIndex: 'full_name',
        },
        {
            key: 'role',
            title: 'Phân quyền',
            dataIndex: 'role',
            render: (_, data: IUser) => (
                <Button type='text' onClick={() => setModalPermissions({ data, isOpen: true })}>
                    <Tag color='blue'>{data.role?.name}</Tag>
                </Button>
            ),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: IUser) => (
                <ActionEditDelete
                    canUpdate={account.canUpdateUser}
                    dataUpdate={data}
                    linkUpdate={USER + UPDATE}
                    canDelete={account.canDeleteUser}
                    hanldeDelete={() => setModalDelete({ data, isOpen: true })}
                />
            ),
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resUsers = await apiGetListUsers({
                limit: pageSize,
                offset: pageSize * (currentPage - 1)
            })
            if (resUsers.data != null) {
                const listUsers = resUsers.data.users.filter((item: IUser) => item.username !== ADMIN_USERNAME)
                resUsers.data.users = listUsers
                setUsers(resUsers.data)
            } else {
                setUsers({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const resp = await apiDeleteUser(modalDelete.data?.id)
            if (resp.status === 200) {
                setModalDelete({ data: null, isOpen: false })
                toastSuccess('Xoá quản trị viên thành công!')
                fetchData()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                {account.canAddUser && (
                    <Link to={USER + CREATE}>
                        <Button type='primary'>Thêm quản trị viên</Button>
                    </Link>
                )}
            </div>
            <CustomTable
                namePage='quản trị viên'
                columns={columns}
                dataSource={users.users?.sort((a: IUser, b: IUser) => Number(a.id) - Number(b.id))}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={users.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Xoá quản trị viên' open={modalDelete.isOpen}
                onCancel={() => setModalDelete({ data: null, isOpen: false })}
                footer={[
                    <Button onClick={handleDelete} type='primary' danger>
                        Xoá
                    </Button>,
                ]}>
                <div>Bạn có chắc muốn xoá quản trị viên <b>{modalDelete.data?.username}</b>?</div>
            </Modal>
            <Modal title='Chi tiết phân quyền' open={modalPermissions.isOpen}
                onCancel={() => setModalPermissions({ data: null, isOpen: false })}
                footer={[]}>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Space>
                            <Typography.Text strong>Tên đăng nhập</Typography.Text>
                            {modalPermissions.data?.username}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space>
                            <Typography.Text strong>Họ tên</Typography.Text>
                            {modalPermissions.data?.full_name}
                        </Space>
                    </Col>
                </Row>
                <Divider />
                <Space>
                    <Typography.Text strong>Nhóm quản trị:</Typography.Text>
                    {modalPermissions.data?.role?.name}
                </Space>
                <Divider />
                <Typography.Text strong>Phân quyền chi tiết:</Typography.Text>
                <Row style={{ marginTop: 10 }}>
                    {modalPermissions.data?.role?.permissions?.map((perm: IPermission, index: Key) => {
                        const result = permissionOptions
                            .map(item => item.value === `${perm.action}::${perm.resource}` && item.label)
                            .filter(item => item)[0]
                        return result && <Tag key={index} color='purple'>{result}</Tag>
                    })}
                </Row>
            </Modal>
        </div>
    )
}
