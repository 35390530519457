import { Pagination, Table } from 'antd'

export default function CustomTable({ expandable, namePage, columns, dataSource, total, pageSize, setPageSize, currentPage, setCurrentPage, isHiddenPagination }: any) {
  return (
    <Table
      rowKey={(_, index: any) => index}
      style={{ overflow: 'auto' }}
      columns={columns}
      dataSource={dataSource}
      expandable={expandable}
      pagination={false}
      footer={() => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <div>Tổng {total ? total : '0'} {namePage}</div>
          {!isHiddenPagination && <Pagination
            showSizeChanger
            pageSize={pageSize}
            onShowSizeChange={(_current, size) => setPageSize(size)}
            total={total}
            current={currentPage}
            onChange={activePage => setCurrentPage(activePage)} />}
        </div>
      )} />
  )
}
