import React, { useCallback, useEffect, useState } from 'react'
import { getSession } from '../../redux/slices/authSlice'
import { useDispatch } from 'react-redux'
import { Button, Card, Col, Modal, Row, Tabs, TabsProps } from 'antd'
import Overview from './Overview'
import { toastError, toastSuccess } from '../../utils/toast'
import UpdateInfo from './UpdateInfo'
import ChangePassword from './ChangePassword'
import { IUser } from '../../models/user.model'
import { useLoading } from "../../context/LoadingContext"
import userImg from '../../assets/images/user.png'
import StatusTag from '../../components/tag/StatusTag'
import { apiUpdateUser } from '../../api'

const ProfilePage: React.FC = () => {
  const [admin, setAdmin] = useState<IUser>()
  const dispatch = useDispatch()
  const { setIsLoading } = useLoading()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const sessionResp = await dispatch(getSession()).unwrap()
      setAdmin(sessionResp.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      toastError('Có lỗi xảy ra! Vui lòng kiểm tra lại')
      setIsLoading(false)
    }
  }, [dispatch, setIsLoading])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const itemsTab: TabsProps['items'] = !admin ? [] : [
    {
      key: 'overview',
      label: 'Tổng quan',
      children: <Overview admin={admin} />,
    },
    {
      key: 'update-info',
      label: 'Cập nhật thông tin',
      children: <UpdateInfo admin={admin} setAdmin={setAdmin} />,
    },
    {
      key: 'change-password',
      label: 'Đổi mật khẩu',
      children: <ChangePassword admin={admin} />,
    },
  ]

  const handleUpdateActive = async () => {
    try {
      setIsLoading(true)
      const resp = await apiUpdateUser({ id: admin?.id, active: !admin?.active })
      if (resp.status === 200) {
        toastSuccess('Cập nhật trạng thái thành công!')
        fetchData()
        setIsOpenModal(false)
      } else {
        toastError('Có lỗi xảy ra!')
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      toastError('Có lỗi xảy ra!')
      setIsLoading(false)
    }
  }

  return (
    <div className='profile-container'>
      <Card>
        {admin && (
          <Row justify='start'>
            <Col xs={24} sm={24} lg={8}>
              <div style={{ textAlign: 'center', margin: 12 }}>
                <img style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: '50%', border: '2px solid #f0f0f0' }} src={userImg} alt='User' />
                <h2>{admin.full_name ? admin.full_name : admin.username}</h2>
                <Button type='text' onClick={() => setIsOpenModal(true)}>
                  <StatusTag status={admin.active} />
                </Button>
              </div>
            </Col>
            <Col xs={24} md={24} lg={16}>
              <div style={{ maxWidth: 500 }}>
                <Tabs defaultActiveKey='overview' items={itemsTab} ></Tabs>
              </div>
            </Col>
          </Row>
        )}
      </Card>
      <Modal title='Cập nhật trạng thái' open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={[
          <Button onClick={handleUpdateActive} type='primary' color='green' danger={admin?.active}>
            {admin?.active ? 'Khoá lại' : 'Mở khoá'}
          </Button>,
        ]}>
        <div>Bạn có chắc muốn cập nhật trạng thái người dùng?</div>
      </Modal>
    </div>
  )
}

export default ProfilePage
