import { Outlet, useNavigate } from 'react-router-dom'
import Loading from '../../components/loading'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { useEffect } from 'react'
import { HOME } from '../../assets/constants/route_path'
import { IDLE_SESSION_TIMEOUT_MINUTES } from '../../config/constants'
import { ILogoutMetadata, LogoutType } from '../../models/logout.model'
import NAME_SPACES from '../../i18n/helpers/nameSpaces'
import { useGetContentTranslation } from '../../hook'

export default function AuthPage() {
    const authState = useSelector(authSelector)
    const navigate = useNavigate()
    const { logoutMetadata } = authState
    useEffect(() => {
        if (authState.isAuthenticated) {
            navigate(HOME)
        }
    }, [authState, navigate])
    return <div>
        {!authState.isLoading ? <>
            {RenderFlashMessage(logoutMetadata)}
            <Outlet />
        </> :
            <Loading />}
    </div>
}

function RenderFlashMessage(metadata: ILogoutMetadata) {
    const msgIdleSessionTimeout = useGetContentTranslation('idleSessionTimeout', NAME_SPACES.AUTH, { 'timeoutMinutes': IDLE_SESSION_TIMEOUT_MINUTES })

    return (
        metadata && metadata.logoutType === LogoutType.IDLE_SESSION_TIMEOUT && (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                textAlign: 'center',
                padding: '10px 0',
                fontWeight: 600,
                color: 'red'
            }}>
                {msgIdleSessionTimeout}
            </div>
        )
    )
}
