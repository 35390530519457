import { useCallback, useEffect, useState } from 'react'
import { Form, Input, Select, TableProps, Tag } from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListCompanies, apiGetListPackages, apiGetListTransactions } from '../../api'
import FormSearch from '../../components/form'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { ITransaction, ITransactions } from '../../models/transaction.modal'
import { IPackage } from '../../models/package.modal'
import { ICompany } from '../../models/company.modal'
import { formatCurrency } from '../../utils/string-utils'

export default function KitSimSubscriber() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [packageOptions, setPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const [transactions, setTransactions] = useState<ITransactions>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        customer: '',
        msisdn: '',
        serialSim: '',
        company_id: 0,
        package_id: 0,
    })

    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự tạo',
            dataIndex: 'ownerBy',
            render: () => 'nghia'
        },
        {
            key: 'customerName',
            title: 'Tên khách hàng',
            dataIndex: 'customerName',
            render: () => 'Le Van A'
        },
        {
            key: 'msisdn',
            title: 'Msisdn',
            dataIndex: 'msisdn',
        },
        {
            key: 'serialSim',
            title: 'SerialSim',
            dataIndex: 'serialSim',
        },
        {
            key: 'customer',
            title: 'Điện thoại',
            dataIndex: 'customer',
        },
        {
            key: 'package',
            title: 'Gói cước hiện tại',
            render: (_, data: ITransaction) => data.package?.name || Number(data?.package_id),
        },
        {
            key: 'startDate',
            title: 'Ngày bắt đầu',
            dataIndex: 'startDate',
        },
        {
            key: 'endDate',
            title: 'Ngày kết thúc',
            dataIndex: 'endDate',
        },
        {
            key: 'company',
            title: 'Công ty',
            render: (_, data: ITransaction) => data.company?.name || Number(data?.company_id),
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus('SUCCESS')
        }
    ]

    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === 'SUCCESS') return <Tag style={style} color="#9b9b05">Thành công</Tag>
        if (state === 'FAIL') return <Tag style={style} color="#c93c3c">Thất bại</Tag>
        if (state === 'ERROR') return <Tag style={style} color="#c93c3c">Lỗi</Tag>
        if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
        return <Tag style={style} color="#2faac6">Đã tạo</Tag>
    }

    const fetchTransactions = useCallback(async () => {
        try {
            setIsLoading(true)
            const resTransactions = await apiGetListTransactions({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                customer: search.customer,
                msisdn: search.msisdn,
                serialSim: search.serialSim,
                company_id: search.company_id,
                package_id: search.package_id,
            })
            if (resTransactions.data === null) {
                setTransactions({})
                return
            }
            setTransactions(resTransactions.data)
            handleMappingTransactions(resTransactions.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const handleMappingTransactions = async (respTran: any) => {
        try {
            const [respPackage, respCompany] = await Promise.all([
                apiGetListPackages({ limit: defaultParams.MAX_LIMIT }),
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            ])
            if (respPackage.data === null && respCompany.data === null) {
                return
            }
            const resp = { count: respTran.count, transactions: [] } as ITransactions
            const listTransactions = respTran.transactions
            const listPackages = respPackage.data.packages
            const listCompanies = respCompany.data.companies
            for (let i = 0; i < listTransactions.length; i++) {
                const transactionInfo = listTransactions[i]
                const packInfo = listPackages.find((pack: IPackage) => pack.id === transactionInfo.package_id)
                const companyInfo = listCompanies.find((company: ICompany) => company.id === transactionInfo.company_id)
                resp.transactions?.push({
                    ...transactionInfo,
                    package: packInfo || { id: transactionInfo.package_id },
                    company: companyInfo || { id: transactionInfo.company_id },
                })
            }
            setTransactions(resp)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchOptions = useCallback(async () => {
        try {
            const [respPackage, respCompany] = await Promise.all([
                apiGetListPackages({ limit: defaultParams.MAX_LIMIT }),
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            ])
            if (respPackage.data?.packages === null && respCompany.data?.companies === null) {
                return
            }

            const packOptions = respPackage.data.packages.map((item: IPackage) => ({
                label: `${item.id} - ${item.name} - ${formatCurrency(Number(item.price))}/${item.numberDay} ngày`,
                value: item.id
            }))
            setPackageOptions(packOptions)
            const compOptions = respCompany.data.companies.map((item: ICompany) => ({
                label: `${item.id} - ${item.name}`,
                value: item.id
            }))
            setCompanyOptions(compOptions)
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchTransactions()
        fetchOptions()
    }, [fetchTransactions, fetchOptions])

    const handleSearch = ({ customer, msisdn, serialSim, company_id, package_id }: any) => {
        if (customer || msisdn || serialSim || company_id || package_id) {
            setSearch({ customer, msisdn, serialSim, company_id, package_id })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            customer: '',
            msisdn: '',
            serialSim: '',
            company_id: 0,
            package_id: 0,
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='msisdn' label='Msisdn'>
                    <Input />
                </Form.Item>
                <Form.Item name='serialSim' label='SerialSim'>
                    <Input />
                </Form.Item>
                <Form.Item name='customer' label='Điện thoại'>
                    <Input />
                </Form.Item>
                <Form.Item name='company_id' label='Công ty'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='package_id' label='Gói cước'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn gói cước"
                        options={packageOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
            </FormSearch>
            <CustomTable
                namePage='giao dịch'
                columns={columns}
                dataSource={transactions.transactions}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={transactions.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}
