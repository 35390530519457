import { Line } from 'react-chartjs-2'
import { colorPalette } from '../../utils/color-utils';

export default function ChartConnectByDay({ connect }: any) {
    const data = {
        labels: connect[0]?.days?.map((item: any) => item.day),
        datasets: connect.map((item: any, index: any) => ({
            label: item.month,
            data: item.days?.map((day: any) => day.sum),
            borderWidth: 2,
            datalabels: {
                color: colorPalette[index],
            },
            hidden: connect.length - index > 2,
        })),
    } as any

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: true,
                text: 'SL sim đấu nối theo ngày',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={data} options={options} />
    )
}
