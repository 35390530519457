export const colorPalette = [
    'rgb(54, 162, 235)',   // Blue
    'rgb(255, 99, 132)',   // Red
    'rgb(255, 206, 86)',   // Yellow
    'rgb(75, 192, 192)',   // Green
    'rgb(153, 102, 255)',  // Purple
    'rgb(255, 159, 64)',   // Orange
    'rgb(255, 102, 178)',  // Pink
    'rgb(0, 206, 209)',    // Teal
    'rgb(0, 102, 204)',    // Dark Blue
    'rgb(201, 203, 207)',  // Gray
    'rgb(50, 205, 50)',    // Lime Green
    'rgb(255, 0, 255)'     // Magenta
];