import { Button, Form, Input, Select, Space } from 'antd'
import { toastError, toastSuccess } from '../../utils/toast'
import { apiUpdateInfo } from '../../api'
import { useDispatch } from 'react-redux'
import { getSession } from '../../redux/slices/authSlice'
import { useLoading } from "../../context/LoadingContext"
import { userStatusOptions } from '../../assets/constants/default_options'
import { useEffect } from 'react'

export default function UpdateInfo({ admin, setAdmin }: any) {
    const [formUpdate] = Form.useForm()
    const dispatch = useDispatch()
    const { setIsLoading } = useLoading()

    useEffect(() => {
        if (formUpdate && admin) {
            formUpdate.resetFields()
        }
    }, [admin, formUpdate])

    const handleUpdate = async (data: any) => {
        const newData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== undefined)
        )
        if (Object.keys(newData).length === 0) {
            toastError('Chưa có thông  tin nào thay đổi!')
            return
        }

        try {
            setIsLoading(true)
            if (newData.active === undefined) {
                newData.active = admin.active
            }
            const resp = await apiUpdateInfo({ id: admin.id, ...newData })
            if (resp.status === 200) {
                const sessionResp = await dispatch(getSession()).unwrap()
                setAdmin(sessionResp.data)
                toastSuccess('Cập nhật tài khoản thành công!')
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error: any) {
            const err = error?._data?.message || 'Có lỗi xảy ra!'
            toastError(err)
            setIsLoading(false)
        }
    }

    return (
        <Form form={formUpdate} onFinish={handleUpdate} layout='vertical'>
            <Form.Item name='full_name' label='Họ tên'>
                <Input placeholder={admin.full_name} />
            </Form.Item>
            <Form.Item label='Tên đăng nhập'>
                <Input placeholder={admin.username} disabled />
            </Form.Item>
            <Form.Item label='Vai trò'>
                <Input placeholder={admin.role?.name} disabled />
            </Form.Item>
            <Form.Item name='email' label='Email'>
                <Input type='email' placeholder={admin.email} />
            </Form.Item>
            <Form.Item name='country' label='Quốc gia'>
                <Input placeholder={admin.country} />
            </Form.Item>
            <Form.Item name='address' label='Địa chỉ'>
                <Input placeholder={admin.address} />
            </Form.Item>
            <Form.Item name='active' label='Trạng thái tài khoản'>
                <Select
                    defaultValue={admin.active}
                    style={{ width: '100%' }}
                    options={userStatusOptions}
                />
            </Form.Item>
            <Space>
                <Button key='submit' type='primary' htmlType='submit'>
                    Xác nhận
                </Button>
                <Button type='dashed' htmlType='reset'>
                    Huỷ bỏ
                </Button>
            </Space>
        </Form>
    )
}
