import { Key, useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Modal, Row, Select, Space, TableProps, Tag, Typography } from 'antd'
import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { apiDeleteUserPartner, apiGetListCompanies, apiGetListUserPartners } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { IPermission } from '../../models/role.model'
import { Link } from 'react-router-dom'
import { COMPANY, CREATE, UPDATE, USER_PARTNER } from '../../assets/constants/route_path'
import { toastError, toastSuccess } from '../../utils/toast'
import ActionEditDelete from '../../components/dropdown/ActionEditDelete'
import { permissionOptions } from '../../assets/constants/default_options'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { ICompany } from '../../models/company.modal'

export default function UserPartner() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [users, setUsers] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({ cpCode: '' })
    const [modalDelete, setModalDelete] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalPermissions, setModalPermissions] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()
    const authState = useSelector(authSelector)
    const { account } = authState

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'username',
            title: 'Tên đăng nhập',
            dataIndex: 'username',
        },
        {
            key: 'cpCode',
            title: 'Mã công ty',
            dataIndex: 'cpCode',
        },
        {
            key: 'role',
            title: 'Phân quyền',
            dataIndex: 'role',
            render: (_, data: any) => (
                <Button type='text' onClick={() => setModalPermissions({ data, isOpen: true })}>
                    <Tag color='blue'>{data.role?.name}</Tag>
                </Button>
            ),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <ActionEditDelete
                    canUpdate={account.canUpdateUserPartner}
                    dataUpdate={data}
                    linkUpdate={COMPANY + USER_PARTNER + UPDATE}
                    canDelete={account.canDeleteUserPartner}
                    hanldeDelete={() => setModalDelete({ data, isOpen: true })}
                />
            ),
        },
    ]

    const fetchCompanies = useCallback(async () => {
        try {
            const resCompanies = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resCompanies.data.companies !== null) {
                const compOptions = resCompanies.data.companies.map((item: ICompany) => ({
                    label: `${item.name} (${item.code})`,
                    value: item.code
                }))
                setCompanyOptions(compOptions)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = { page: currentPage, size: pageSize } as any
            if (search.cpCode) {
                req.cpCode = search.cpCode
            }
            const resUsers = await apiGetListUserPartners(req)
            if (resUsers.data != null) {
                setUsers(resUsers.data)
            } else {
                setUsers({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    useEffect(() => {
        fetchCompanies()
        fetchData()
    }, [fetchCompanies, fetchData])

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const resp = await apiDeleteUserPartner(modalDelete.data?.id)
            if (resp.status === 200) {
                setModalDelete({ data: null, isOpen: false })
                toastSuccess('Xoá quản trị viên thành công!')
                fetchData()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    const handleSearch = ({ cpCode }: any) => {
        if (cpCode) {
            setSearch({ cpCode })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({ cpCode: '' })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    return (
        <div>
            <Form form={formSearch} onFinish={handleSearch}>
                <Space style={{ display: 'flex', alignItems: 'start' }}>
                    <Form.Item name='cpCode' label='Công ty'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </Space>
            </Form>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                {account.canAddUserPartner && (
                    <Link to={COMPANY + USER_PARTNER + CREATE}>
                        <Button type='primary'>Thêm quản trị viên</Button>
                    </Link>
                )}
            </div>
            <CustomTable
                namePage='quản trị viên'
                columns={columns}
                dataSource={users.user_partners}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={users.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Xoá quản trị viên' open={modalDelete.isOpen}
                onCancel={() => setModalDelete({ data: null, isOpen: false })}
                footer={[
                    <Button onClick={handleDelete} type='primary' danger>
                        Xoá
                    </Button>,
                ]}>
                <div>Bạn có chắc muốn xoá quản trị viên <b>{modalDelete.data?.username}</b>?</div>
            </Modal>
            <Modal title='Chi tiết phân quyền' open={modalPermissions.isOpen}
                onCancel={() => setModalPermissions({ data: null, isOpen: false })}
                footer={[]}>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Space>
                            <Typography.Text strong>Tên đăng nhập</Typography.Text>
                            {modalPermissions.data?.username}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space>
                            <Typography.Text strong>Mã công ty</Typography.Text>
                            {modalPermissions.data?.cpCode}
                        </Space>
                    </Col>
                </Row>
                <Divider />
                <Space>
                    <Typography.Text strong>Nhóm quản trị:</Typography.Text>
                    {modalPermissions.data?.role?.name}
                </Space>
                <Divider />
                <Typography.Text strong>Phân quyền chi tiết:</Typography.Text>
                <Row style={{ marginTop: 10 }}>
                    {modalPermissions.data?.role?.permissions?.map((perm: IPermission, index: Key) => {
                        const result = permissionOptions
                            .map(item => item.value === `${perm.action}::${perm.resource}` && item.label)
                            .filter(item => item)[0]
                        return result && <Tag key={index} color='purple'>{result}</Tag>
                    })}
                </Row>
            </Modal>
        </div>
    )
}
