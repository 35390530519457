import ButtonBack from "../../components/button/ButtonBack"
import { customerInfo, listTransByCustomer } from "../../mocks/customer"
import CustomTable from "../../components/table"
import { Button, Card, DatePicker, Divider, Form, Input, Modal, Select, TableProps, Tag, Typography } from "antd"
import moment from "moment"
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from "react"
import defaultParams, { FORMAT_DATE } from "../../assets/constants/default_params"
import FormSearch from "../../components/form"
import { filterOptionByLabel } from "../../utils/filter-utils"
import { mbhStatusOptions, typeCustomerOptions } from "../../assets/constants/default_options"
import { apiGetListCompanies } from "../../api"
import { ICompany } from "../../models/company.modal"
import { toastSuccess } from "../../utils/toast"
const { Text } = Typography

export default function CustomerDetail() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [modalUpdate, setModalUpdate] = useState(false)

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự tạo',
            dataIndex: 'ownerBy',
        },
        {
            key: 'customerName',
            title: 'Tên khách hàng',
            dataIndex: 'customerName',
        },
        {
            key: 'msisdn',
            title: 'Msisdn',
            dataIndex: 'msisdn',
        },
        {
            key: 'serialSim',
            title: 'SerialSim',
            dataIndex: 'serialSim',
        },
        {
            key: 'customer',
            title: 'Điện thoại',
            dataIndex: 'customer',
        },
        {
            key: 'company',
            title: 'Công ty',
            dataIndex: 'company',
        },
        {
            key: 'package',
            title: 'Gói cước',
            dataIndex: 'package',
        },
        {
            key: 'startDate',
            title: 'Ngày bắt đầu',
            dataIndex: 'startDate',
        },
        {
            key: 'endDate',
            title: 'Ngày kết thúc',
            dataIndex: 'endDate',
        },
        {
            key: 'modify',
            title: 'Ngày tạo',
            dataIndex: 'modify',
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status)
        }
    ]

    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === 'SUCCESS') return <Tag style={style} color="#9b9b05">Thành công</Tag>
        if (state === 'FAIL') return <Tag style={style} color="#c93c3c">Thất bại</Tag>
        if (state === 'ERROR') return <Tag style={style} color="#c93c3c">Lỗi</Tag>
        if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
        return <Tag style={style} color="#2faac6">Đã tạo</Tag>
    }

    const fetchCompanies = useCallback(async () => {
        try {
            const resPackages = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resPackages.data != null) {
                const options = resPackages.data.companies.map((item: ICompany) => ({
                    label: `${item.id} - ${item.name}`,
                    value: item.id
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchCompanies()
    }, [fetchCompanies])

    const handleUpdate = async () => {
        toastSuccess('Cập nhật thông tin thành công!')
        setModalUpdate(false)
    }


    return (
        <div>
            <ButtonBack />
            <Card
                title={<h3>Thông tin khách hàng</h3>}
                extra={<Button onClick={() => setModalUpdate(true)} type="primary">Cập nhật</Button>}
            >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Mã khách hàng: </Text>{customerInfo.customer_id}</p>
                        <p><Text strong>Họ và tên: </Text>{customerInfo.full_name}</p>
                        <p><Text strong>Số giấy tờ: </Text>{customerInfo.number_identity}</p>
                        <p><Text strong>Địa chỉ: </Text>{customerInfo.address}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Nhân sự tạo: </Text>{customerInfo.ownerBy}</p>
                        <p><Text strong>Số điện thoại: </Text>{customerInfo.phone_number}</p>
                        <p><Text strong>Loại giấy tờ: </Text>{customerInfo.type_identity}</p>
                        <p><Text strong>Quốc tịch: </Text>{customerInfo.nation}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p><Text strong>Ngày tạo: </Text>{customerInfo.createdAt}</p>
                        <p><Text strong>Email: </Text>{customerInfo.email}</p>
                        <p><Text strong>Ngày hết hạn: </Text>{customerInfo.expire_date}</p>
                    </div>
                </div>
            </Card>
            <Divider />
            <h2>Lịch sử giao dịch</h2>
            <FormSearch>
                <Form.Item name='company_id' label='Công ty'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='status' label='Trạng thái'>
                    <Select
                        style={{ width: 170 }}
                        showSearch
                        placeholder="Chọn trạng thái"
                        options={mbhStatusOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='serialSim' label='SerialSim'>
                    <Input />
                </Form.Item>
            </FormSearch>
            <CustomTable
                namePage='giao dịch'
                columns={columns}
                dataSource={listTransByCustomer}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={listTransByCustomer.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Cập nhật thông tin khách hàng'
                open={modalUpdate}
                onCancel={() => setModalUpdate(false)}
                footer={[
                    <Button onClick={handleUpdate} type="primary">Cập nhật</Button>
                ]}
            >
                <Form labelCol={{ span: 6 }} labelAlign="left">
                    <Form.Item name='full_name' label='Họ và tên' initialValue={customerInfo.full_name}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='phone_number' label='Số điện thoại' initialValue={customerInfo.phone_number}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label='Email' initialValue={customerInfo.email}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='number_identity' label='Số giấy tờ' initialValue={customerInfo.number_identity}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='type_identity' label='Loại giấy tờ' initialValue={customerInfo.type_identity}>
                        <Select options={typeCustomerOptions} />
                    </Form.Item>
                    <Form.Item name='expire_date' label='Ngày hết hạn'
                        initialValue={dayjs(moment(new Date(customerInfo.expire_date)).format(FORMAT_DATE), FORMAT_DATE)}>
                        <DatePicker
                            style={{ width: '100%' }}
                            allowClear={false}
                            format={FORMAT_DATE}
                        />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ' initialValue={customerInfo.address}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nation' label='Quốc gia' initialValue={customerInfo.nation}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
