import { Key, useCallback, useEffect, useState } from 'react'
import { Button, Modal, TableProps, Tag } from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { apiDeleteRole, apiGetListRoles } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { IPermission, IRole, IRoles } from '../../models/role.model'
import { Link } from 'react-router-dom'
import { CREATE, ROLE, UPDATE } from '../../assets/constants/route_path'
import { toastError, toastSuccess } from '../../utils/toast'
import ActionEditDelete from '../../components/dropdown/ActionEditDelete'
import { permissionOptions } from '../../assets/constants/default_options'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'

export default function Role() {
    const [roles, setRoles] = useState<IRoles>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [modalDelete, setModalDelete] = useState<{ data: IRole | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'name',
            title: 'Tên phân quyền',
            dataIndex: 'name',
        },
        {
            key: 'permissions',
            title: 'Chức năng',
            dataIndex: 'permissions',
            render: (perms: IPermission[]) => perms?.map((perm: IPermission, index: Key) => {
                const result = permissionOptions
                    .map(item => item.value === `${perm.action}::${perm.resource}` && item.label)
                    .filter(item => item)[0]
                return result && <Tag key={index} color="purple">{result}</Tag>
            }),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: IRole) => (
                <ActionEditDelete
                    canUpdate={account.canUpdateRole}
                    dataUpdate={data}
                    linkUpdate={ROLE + UPDATE}
                    canDelete={account.canDeleteRole}
                    hanldeDelete={() => setModalDelete({ data, isOpen: true })}
                />
            ),
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resRoles = await apiGetListRoles({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
            })
            if (resRoles.data != null) {
                const listRoles = resRoles.data.roles.filter((item: IRole) => item.name !== 'Super Admin')
                resRoles.data.roles = listRoles
                setRoles(resRoles.data)
            } else {
                setRoles({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const resp = await apiDeleteRole(modalDelete.data?.id)
            if (resp.status === 200) {
                setModalDelete({ data: null, isOpen: false })
                toastSuccess('Xoá phân quyền thành công!')
                fetchData()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                {account.canAddRole && (
                    <Link to={ROLE + CREATE}>
                        <Button type="primary">Thêm phân quyền</Button>
                    </Link>
                )}
            </div>
            <CustomTable
                namePage="phân quyền"
                columns={columns}
                dataSource={roles.roles?.sort((a: IRole, b: IRole) => Number(a.id) - Number(b.id))}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={roles.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title="Xoá phân quyền" open={modalDelete.isOpen}
                onCancel={() => setModalDelete({ data: null, isOpen: false })}
                footer={[
                    <Button onClick={handleDelete} type="primary" danger>
                        Xoá
                    </Button>,
                ]}>
                <div>Bạn có chắc muốn xoá phân quyền <b>{modalDelete.data?.name}</b>?</div>
            </Modal>
        </div>
    )
}
