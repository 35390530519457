import { Button, Space } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

export default function ButtonSubmitBack() {
    const navigate = useNavigate()

    const handleBackPage = () => {
        navigate(-1)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Space>
                <Button htmlType="submit" icon={<CheckOutlined />} style={{ background: '#008965', color: 'white' }}>
                    <b>Xác nhận</b>
                </Button>
                <Button onClick={handleBackPage} icon={<CloseOutlined />} style={{ background: '#c93c3c', color: 'white' }}>
                    <b>Đóng</b>
                </Button>
            </Space>
        </div>
    )
}
