import { useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Space, TableProps, Tag, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListSimPassports, apiUpdateSimPassport } from '../../api'
import { ISimPassport, ISimPassports } from '../../models/sim-passport.modal'
import { toastError, toastSuccess } from '../../utils/toast'
import FormSearch from '../../components/form'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { simPassportStatusOptions } from '../../assets/constants/default_options'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
const { Text } = Typography

const COMPLETED = 'COMPLETED'
const CANCELLED = 'CANCELLED'

export default function ManagementSimPassport() {
    const [simPassports, setSimPassports] = useState<ISimPassports>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        full_name: '',
        nation: '',
        passport_number: '',
        email: '',
        status: '',
    })
    const [modalStatus, setModalStatus] = useState<{ data: ISimPassport | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'full_name',
            title: 'Họ và tên',
            dataIndex: 'full_name',
        },
        {
            key: 'date_of_birth',
            title: 'Ngày sinh',
            dataIndex: 'date_of_birth',
        },
        {
            key: 'nation',
            title: 'Quốc tịch',
            dataIndex: 'nation',
        },
        {
            key: 'passport_number',
            title: 'Số hộ chiếu',
            dataIndex: 'passport_number',
        },
        {
            key: 'expire_date',
            title: 'Ngày hết hạn',
            dataIndex: 'expire_date',
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: (status) => renderStatus(status),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: ISimPassport) => (
                account.canUpdateSimPassport && <Button
                    disabled={data.status === 'COMPLETED' || data.status === 'CANCELLED'}
                    onClick={() => setModalStatus({ data, isOpen: true })}
                    icon={<EditOutlined />}
                    type='primary'
                >
                    Xử lý
                </Button>
            ),
        },
    ]

    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === COMPLETED) return <Tag style={style} color="#9b9b05">Hoàn thành</Tag>
        if (state === CANCELLED) return <Tag style={style} color="#c93c3c">Đã huỷ bỏ</Tag>
        return <Tag style={style} color="#ff6f00">Chờ xác nhận</Tag>
    }

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resPackages = await apiGetListSimPassports({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                full_name: search.full_name,
                nation: search.nation,
                passport_number: search.passport_number,
                email: search.email,
                status: search.status,
            })
            if (resPackages.data != null) {
                setSimPassports(resPackages.data)
            } else {
                setSimPassports({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ full_name, nation, passport_number, email, status }: any) => {
        if (full_name || nation || passport_number || email || status) {
            setSearch({ full_name, nation, passport_number, email, status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            full_name: '',
            nation: '',
            passport_number: '',
            email: '',
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleUpdateStatus = async (status: string) => {
        try {
            setIsLoading(true)
            const resp = await apiUpdateSimPassport({
                id: Number(modalStatus.data?.id),
                status: status,
            })
            if (resp.status === 200) {
                toastSuccess('Cập nhật trạng thái thành công!')
                fetchData()
                setModalStatus({ data: null, isOpen: false })
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='full_name' label='Tên khách hàng'>
                    <Input />
                </Form.Item>
                <Form.Item name='nation' label='Quốc tịch'>
                    <Input />
                </Form.Item>
                <Form.Item name='passport_number' label='Số hộ chiếu'>
                    <Input />
                </Form.Item>
                <Form.Item name='email' label='Email'>
                    <Input />
                </Form.Item>
                <Form.Item name='status' label='Trạng thái'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn trạng thái"
                        options={simPassportStatusOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
            </FormSearch>
            <CustomTable
                namePage='sim passport'
                columns={columns}
                dataSource={simPassports.sim_passports?.sort((a: ISimPassport, b: ISimPassport) => Number(a.id) - Number(b.id))}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={simPassports.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Cập nhật trạng thái' open={modalStatus.isOpen}
                onCancel={() => setModalStatus({ data: null, isOpen: false })}
                footer={[
                    <Button onClick={() => handleUpdateStatus(COMPLETED)} style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={() => handleUpdateStatus(CANCELLED)} style={{ background: '#c93c3c', color: 'white' }}>
                        Từ chối
                    </Button>,
                ]}>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Space>
                            <Text strong>Họ tên:</Text>
                            {modalStatus.data?.full_name}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space>
                            <Text strong>Email:</Text>
                            {modalStatus.data?.email}
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Space>
                            <Text strong>Quốc tịch:</Text>
                            {modalStatus.data?.nation}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space>
                            <Text strong>Ngày sinh:</Text>
                            {modalStatus.data?.date_of_birth}
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Space>
                            <Text strong>Số hộ chiếu:</Text>
                            {modalStatus.data?.passport_number}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space>
                            <Text strong>Ngày hết hạn:</Text>
                            {modalStatus.data?.expire_date}
                        </Space>
                    </Col>
                </Row>
                <Divider />
                <div>
                    <div>Ảnh hộ chiếu</div>
                    <img
                        style={{ height: 100, width: 'auto', objectFit: 'cover' }}
                        src={modalStatus.data?.passport_url}
                        alt={modalStatus.data?.passport_url}
                    />
                </div>
                <div>
                    <div>Ảnh đại diện</div>
                    <img
                        style={{ height: 100, width: 'auto', objectFit: 'cover' }}
                        src={modalStatus.data?.profile_url}
                        alt={modalStatus.data?.profile_url}
                    />
                </div>
                <div>
                    <div>Ảnh serial sim</div>
                    <img
                        style={{ height: 100, width: 'auto', objectFit: 'cover' }}
                        src={modalStatus.data?.sim_serial_url}
                        alt={modalStatus.data?.sim_serial_url}
                    />
                </div>
            </Modal>
        </div>
    )
}
