import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Input, Modal, TableProps } from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { apiDeleteCompany, apiGetListCompanies, apiGetListPackages } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { ICompanies, ICompany } from '../../models/company.modal'
import { Link } from 'react-router-dom'
import { COMPANY, CREATE, UPDATE } from '../../assets/constants/route_path'
import { toastError, toastSuccess } from '../../utils/toast'
import ActionEditDelete from '../../components/dropdown/ActionEditDelete'
import { IPackage } from '../../models/package.modal'
import FormSearch from '../../components/form'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'

export default function Company() {
    const [companies, setCompanies] = useState<ICompanies>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        name: '',
        code: '',
    })
    const [modalDelete, setModalDelete] = useState<{ data: ICompany | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'name',
            title: 'Tên công ty',
            dataIndex: 'name',
        },
        {
            key: 'code',
            title: 'Mã công ty',
            dataIndex: 'code',
        },
        {
            key: 'packageRenew',
            title: 'Gói cước gợi ý',
            render: (_, data: ICompany) => data.packageRenew?.name || Number(data?.packageNewId),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: ICompany) => (
                <ActionEditDelete
                    canUpdate={account.canUpdateCompany}
                    dataUpdate={data}
                    linkUpdate={COMPANY + UPDATE}
                    canDelete={account.canDeleteCompany}
                    hanldeDelete={() => setModalDelete({ data, isOpen: true })}
                />
            ),
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resCompanies = await apiGetListCompanies({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                name: search.name,
                code: search.code,
            })
            if (resCompanies.data === null) {
                setCompanies({})
                return
            }
            setCompanies(resCompanies.data)
            handleMappingCompanies(resCompanies.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const handleMappingCompanies = async (respComp: any) => {
        try {
            const respPackage = await apiGetListPackages({ limit: defaultParams.MAX_LIMIT })
            if (respPackage.data === null) {
                return
            }
            const resp = { count: respComp.count, companies: [] } as ICompanies
            const listCompanies = respComp.companies
            const listPackages = respPackage.data.packages
            for (let i = 0; i < listCompanies.length; i++) {
                const companyInfo = listCompanies[i]
                const packInfo = listPackages.find((pack: IPackage) => pack.id === companyInfo.packageNewId)
                resp.companies?.push({
                    ...companyInfo,
                    packageRenew: packInfo || { id: companyInfo.package_id },
                })
            }
            setCompanies(resp)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ name, code }: any) => {
        if (name || code) {
            setSearch({ name, code })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            name: '',
            code: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const resp = await apiDeleteCompany(modalDelete.data?.id)
            if (resp.status === 200) {
                setModalDelete({ data: null, isOpen: false })
                toastSuccess('Xoá công ty thành công!')
                fetchData()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='name' label='Tên công ty'>
                    <Input />
                </Form.Item>
                <Form.Item name='code' label='Mã công ty'>
                    <Input />
                </Form.Item>
            </FormSearch>
            {account.canAddCompany && (
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Link to={COMPANY + CREATE}>
                        <Button type='primary'>Thêm công ty</Button>
                    </Link>
                </div>
            )}
            <CustomTable
                namePage='công ty'
                columns={columns}
                dataSource={companies.companies?.sort((a: ICompany, b: ICompany) => Number(a.id) - Number(b.id))}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={companies.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Xoá công ty' open={modalDelete.isOpen}
                onCancel={() => setModalDelete({ data: null, isOpen: false })}
                footer={[
                    <Button onClick={handleDelete} type='primary' danger>
                        Xoá
                    </Button>,
                ]}>
                <div>Bạn có chắc muốn xoá công ty <b>{modalDelete.data?.name}</b>?</div>
            </Modal>
        </div>
    )
}
