import { Descriptions, DescriptionsProps } from 'antd'

export default function Overview({ admin }: any) {
    const items: DescriptionsProps['items'] = [
        {
            key: 'full_name',
            label: 'Họ tên',
            children: admin?.full_name,
            span: 2,
        },
        {
            key: 'username',
            label: 'Tên tài khoản',
            children: admin?.username,
        },
        {
            key: 'email',
            label: 'Email',
            children: admin?.email,
        },
        {
            key: 'country',
            label: 'Quốc gia',
            children: admin?.country,
        },
        {
            key: 'address',
            label: 'Địa chỉ',
            children: admin?.address,
        },
    ]
    return (
        <div>
            <Descriptions
                items={items}
                column={2}
                title={
                    <span style={{ fontWeight: 400 }}>
                        Nhóm quản trị: <b>{admin.role?.name}</b>
                    </span>}
            />
        </div>
    )
}
