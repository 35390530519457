import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Select, TableProps } from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { apiDeleteTelegram, apiGetListCompanies, apiGetListPackages, apiGetListTelegrams } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { ITelegram, ITelegrams } from '../../models/telegram.modal'
import { CREATE, TELEGRAM, UPDATE } from '../../assets/constants/route_path'
import { Link } from 'react-router-dom'
import { toastError, toastSuccess } from '../../utils/toast'
import ActionEditDelete from '../../components/dropdown/ActionEditDelete'
import { ICompany } from '../../models/company.modal'
import { IPackage } from '../../models/package.modal'
import FormSearch from '../../components/form'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { formatCurrency } from '../../utils/string-utils'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'

export default function Telegram() {
    const [telegrams, setTelegrams] = useState<ITelegrams>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [packageOptions, setPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const [search, setSearch] = useState({
        room_id: '',
        prefix_packages: '',
        company_id: 0,
        package_id: 0,
    })
    const [modalDelete, setModalDelete] = useState<{ data: ITelegram | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'room_id',
            title: 'Phòng',
            dataIndex: 'room_id',
        },
        {
            key: 'company',
            title: 'Công ty',
            render: (_, data: ITelegram) => data.company?.name || Number(data?.company_id),
        },
        {
            key: 'package',
            title: 'Gói cước',
            render: (_, data: ITelegram) => data.package?.name || Number(data?.package_id),
        },
        {
            key: 'prefix_packages',
            title: 'Chu kỳ',
            dataIndex: 'prefix_packages',
            render: (prefix_packages) => `${prefix_packages} tháng`,
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: ITelegram) => (
                <ActionEditDelete
                    canUpdate={account.canUpdateTelegram}
                    dataUpdate={data}
                    linkUpdate={TELEGRAM + UPDATE}
                    canDelete={account.canDeleteTelegram}
                    hanldeDelete={() => setModalDelete({ data, isOpen: true })}
                />
            ),
        },
    ]

    const fetchOptions = useCallback(async () => {
        try {
            const [respPackage, respCompany] = await Promise.all([
                apiGetListPackages({ limit: defaultParams.MAX_LIMIT }),
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            ])
            if (respPackage.data?.packages === null && respCompany.data?.companies === null) {
                return
            }

            const packOptions = respPackage.data.packages.map((item: IPackage) => ({
                label: `${item.id} - ${item.name} - ${formatCurrency(Number(item.price))}/${item.numberDay} ngày`,
                value: item.id
            }))
            setPackageOptions(packOptions)
            const compOptions = respCompany.data.companies.map((item: ICompany) => ({
                label: `${item.id} - ${item.name}`,
                value: item.id
            }))
            setCompanyOptions(compOptions)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resTelegrams = await apiGetListTelegrams({
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                room_id: search.room_id,
                prefix_packages: search.prefix_packages,
                company_id: search.company_id,
                package_id: search.package_id,
            })
            if (resTelegrams.data === null) {
                setTelegrams({})
                return
            }
            setTelegrams(resTelegrams.data)
            handleMappingTelegrams(resTelegrams.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const handleMappingTelegrams = async (respTele: any) => {
        try {
            const [respPackage, respCompany] = await Promise.all([
                apiGetListPackages({ limit: defaultParams.MAX_LIMIT }),
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            ])
            if (respPackage.data === null && respCompany.data === null) {
                return
            }

            const resp = { count: respTele.count, telegrams: [] } as ITelegrams
            const listTelegrams = respTele.telegrams
            const listPackages = respPackage.data.packages
            const listCompanies = respCompany.data.companies
            for (let i = 0; i < listTelegrams.length; i++) {
                const telegramInfo = listTelegrams[i]
                const packInfo = listPackages.find((pack: IPackage) => pack.id === telegramInfo.package_id)
                const companyInfo = listCompanies.find((company: ICompany) => company.id === telegramInfo.company_id)
                resp.telegrams?.push({
                    ...telegramInfo,
                    package: packInfo || { id: telegramInfo.package_id },
                    company: companyInfo || { id: telegramInfo.company_id },
                })
            }
            setTelegrams(resp)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
        fetchOptions()
    }, [fetchData, fetchOptions])

    const handleSearch = ({ room_id, prefix_packages, company_id, package_id }: any) => {
        if (room_id || prefix_packages || company_id || package_id) {
            setSearch({ room_id, prefix_packages, company_id, package_id })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            room_id: '',
            prefix_packages: '',
            company_id: 0,
            package_id: 0,
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const resp = await apiDeleteTelegram(modalDelete.data?.id)
            if (resp.status === 200) {
                setModalDelete({ data: null, isOpen: false })
                toastSuccess('Xoá telegram thành công!')
                fetchData()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='room_id' label='Phòng'>
                    <Input />
                </Form.Item>
                <Form.Item name='prefix_packages' label='Chu kỳ'>
                    <Input />
                </Form.Item>
                <Form.Item name='company_id' label='Công ty'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='package_id' label='Gói cước'>
                    <Select
                        style={{ minWidth: 170 }}
                        showSearch
                        placeholder="Chọn gói cước"
                        options={packageOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
            </FormSearch>
            {account.canAddTelegram && (
                <div style={{ textAlign: 'end', marginBottom: 20 }}>
                    <Link to={TELEGRAM + CREATE}>
                        <Button type='primary'>Thêm telegram</Button>
                    </Link>
                </div>
            )}
            <CustomTable
                namePage='telegram'
                columns={columns}
                dataSource={telegrams.telegrams?.sort((a: ITelegram, b: ITelegram) => Number(a.id) - Number(b.id))}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={telegrams.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Xoá telegram' open={modalDelete.isOpen}
                onCancel={() => setModalDelete({ data: null, isOpen: false })}
                footer={[
                    <Button onClick={handleDelete} type='primary' danger>
                        Xoá
                    </Button>,
                ]}>
                <div>Bạn có chắc muốn xoá telegram <b>{modalDelete.data?.id?.toString()}</b>?</div>
            </Modal>
        </div>
    )
}
