import { useCallback, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useLoading } from '../../context/LoadingContext'
import { apiCheckWalletBalancePostpaid } from '../../api'
import { Button, Divider, Space } from 'antd'
import { formatCurrency } from '../../utils/string-utils'

export default function RegisterAddonOverview() {
    const [balance, setBalance] = useState<any>()

    const { setIsLoading } = useLoading()

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiCheckWalletBalancePostpaid()
            if (resp.data.data != null) {
                setBalance(resp.data.data)
            } else {
                setBalance({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56, 55, 40],
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
            },
        ],
    }

    return (
        <div>
            <Space>
                <h2>Số dư: {formatCurrency(balance?.balance || 0)}</h2>
                <Divider />
                <Button onClick={fetchData}>Làm mới</Button>
            </Space>
            <Line data={data} />
        </div>
    )
}
