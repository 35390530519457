import { Button, Dropdown, Menu } from 'antd'
import { DownOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'

type FieldEditDelete = {
    handleView: Function,
    canDelete: boolean,
    hanldeDelete: Function,
}

export default function Action({ handleView, canDelete, hanldeDelete }: FieldEditDelete) {
    return (
        <Dropdown
            overlay={
                <Menu>
                    <Menu.Item>
                        <Button icon={<EyeOutlined />} onClick={() => handleView()}
                            style={{ width: 100, background: '#3e64b5', color: 'white' }}>
                            Chi tiết
                        </Button>
                    </Menu.Item>
                    {canDelete && <Menu.Item>
                        <Button style={{ width: 100 }} onClick={() => hanldeDelete()}
                            icon={<DeleteOutlined />} type='primary' danger>
                            Xoá
                        </Button>
                    </Menu.Item>}
                </Menu>
            }
        >
            <Button style={{ background: '#289e45', color: 'white' }}>
                Chức năng <DownOutlined />
            </Button>
        </Dropdown>
    )
}
