import { Line } from 'react-chartjs-2'
import { colorPalette } from '../../utils/color-utils'

export default function ChartConnectByMonth({ record, filter }: any) {
    const data = {
        labels: record.map((item: any) => item.month),
        datasets: [
            {
                type: 'line',
                label: 'SĐT KH đã lọc',
                data: filter.map((item: any) => item.sum),
                borderWidth: 2,
                datalabels: {
                    color: colorPalette[4],
                },
            },
            {
                type: 'bar',
                label: 'SL sim đấu nối',
                data: record.map((item: any) => item.sum),
                borderWidth: 2,
                datalabels: {
                    color: colorPalette[6],
                },
            },
        ],
    } as any

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: true,
                text: 'SL sim đấu nối và SĐT khách hàng',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                font: {
                    size: 14,
                    weight: 'bold',
                },
                anchor: 'end',
                align: 'top',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={data} options={options} />
    )
}
