import { Form, Input, Select } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import { ICompany, IUpdateCompany } from "../../models/company.modal"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiGetListPackages, apiUpdateCompany } from "../../api"
import { useLocation, useNavigate } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { COMPANY } from "../../assets/constants/route_path"
import { IPackage } from "../../models/package.modal"
import { formatCurrency } from "../../utils/string-utils"
import defaultParams from "../../assets/constants/default_params"
import { filterOptionByLabel } from "../../utils/filter-utils"

export default function CompanyUpdate() {
    const [packageOptions, setPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const [formUpdate] = Form.useForm()
    const { setIsLoading } = useLoading()
    const location = useLocation()
    const navigate = useNavigate()
    const company = location.state as ICompany

    const fetchPackages = useCallback(async () => {
        try {
            setIsLoading(true)
            const resPackages = await apiGetListPackages({ limit: defaultParams.MAX_LIMIT })
            if (resPackages.data != null) {
                const options = resPackages.data.packages.map((item: IPackage) => ({
                    label: `${item.id} - ${item.name} - ${formatCurrency(Number(item.price))}/${item.numberDay} ngày`,
                    value: item.id
                }))
                setPackageOptions(options)
            } else {
                setPackageOptions([])
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    useEffect(() => {
        if (!company) {
            navigate(COMPANY)
        }
        fetchPackages()
    }, [company, navigate, fetchPackages])

    const handleUpdate = async (values: IUpdateCompany) => {
        try {
            setIsLoading(true)
            const resp = await apiUpdateCompany({ ...values, id: Number(company.id) })
            if (resp.status === 200) {
                toastSuccess('Cập nhật công ty thành công!')
                formUpdate.resetFields()
                navigate(COMPANY)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Form form={formUpdate} onFinish={handleUpdate} layout="vertical">
                <Form.Item name='name' label='Tên công ty' initialValue={company.name}
                    rules={[{ required: true, message: 'Vui lòng nhập tên công ty!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='code' label='Mã công ty' initialValue={company.code}
                    rules={[{ required: true, message: 'Vui lòng nhập mã công ty!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='templateId' label='ID Template' initialValue={company.templateId}>
                    <Input />
                </Form.Item>
                <Form.Item name='packageNewId' label='Gói cước gợi ý' initialValue={company.packageRenew?.id}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn gói cước"
                        options={packageOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='description' label='Mô tả' initialValue={company.description}>
                    <Input />
                </Form.Item>
                <ButtonSubmitBack />
            </Form>
        </div>
    )
}
