export const kitSims = {
    total: 2,
    orders: [
        {
            "orderId": "order-1721392154360",
            "ownerBy": 'nghiaved',
            "description": "nap 1 sim 23/7/2024",
            "company_id": 5,
            "total": 1,
            "totalSuccess": 1,
            "totalFail": 0,
            "reqTime": 1721730210,
            "status": "COMPLETED",
        },
        {
            "orderId": "order-1721304751558",
            "ownerBy": 'admin',
            "description": "nap 669 sim 23/7/2024",
            "company_id": 6,
            "total": 669,
            "totalSuccess": 0,
            "totalFail": 0,
            "reqTime": 1721732993,
            "status": "CREATED",
        }
    ]
}