import { Button, Space, Form } from 'antd';
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";

export default function FormSearch({ children, form, handleSearch, handleClearSearch }: any) {
    return (
        <Form
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', marginBottom: 20 }}
            form={form} onFinish={handleSearch} id="searchForm" layout='vertical' autoComplete='off'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexWrap: 'wrap', gap: 20, width: '100%' }}>
                {children}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                <Space>
                    <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                    <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                </Space>
            </div>
        </Form>
    )
}
