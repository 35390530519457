import { Button, Form, Input, Modal, Select } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiChangePassword, apiGetListRoles, apiUpdateUser } from "../../api"
import { useLocation, useNavigate } from "react-router-dom"
import { IUser } from "../../models/user.model"
import { USER } from "../../assets/constants/route_path"
import { useCallback, useEffect, useState } from "react"
import defaultParams from "../../assets/constants/default_params"
import { IRole } from "../../models/role.model"
import { filterOptionByLabel } from "../../utils/filter-utils"
import { userStatusOptions } from "../../assets/constants/default_options"

export default function UserUpdate() {
    const [roleOptions, setRoleOptions] = useState<{ label: string, value: Number }[]>([])
    const [formUpdate] = Form.useForm()
    const [formChangePassword] = Form.useForm()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const { setIsLoading } = useLoading()
    const location = useLocation()
    const navigate = useNavigate()
    const user = location.state as IUser

    const fetchRoles = useCallback(async () => {
        try {
            setIsLoading(true)
            const resRoles = await apiGetListRoles({ limit: defaultParams.MAX_LIMIT })
            if (resRoles.data != null) {
                const listRoles = resRoles.data.roles.filter((item: IRole) => item.name !== 'Super Admin')
                const options = listRoles.map((item: IRole) => ({
                    label: item.name,
                    value: item.id
                }))
                setRoleOptions(options)
            } else {
                setRoleOptions([])
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    const handleUpdate = async (data: any) => {
        try {
            setIsLoading(true)
            const resp = await apiUpdateUser({ id: user.id, ...data })
            if (resp.status === 200) {
                toastSuccess('Cập nhật quản trị viên thành công!')
                formUpdate.resetFields()
                navigate(USER)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error: any) {
            const err = error?._data?.message || 'Có lỗi xảy ra!'
            toastError(err)
            setIsLoading(false)
        }
    }

    const handleChangePassword = async (data: any) => {
        const { old_password, password, re_password } = data
        if (password !== re_password) {
            toastError('Nhắc lại mật khẩu không chính xác!')
            return
        }

        try {
            setIsLoading(true)
            // let hashOldPassword = md5(old_password, SECRET_KEY)
            // let hashPassword = md5(password, SECRET_KEY)
            // if (admin.username === 'admin') {
            //     hashOldPassword = old_password
            //     hashPassword = password
            // }

            const resp = await apiChangePassword({
                id: Number(user.id),
                old_password: old_password,
                new_password: password,
            })

            if (resp.status === 200) {
                toastSuccess('Đổi mật khẩu thành công!')
                formChangePassword.resetFields()
                setIsOpenModal(false)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            const message = error ? 'Mật khẩu không chính xác!' : 'Có lỗi xảy ra!'
            toastError(message)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!user) {
            navigate(user)
        }
        fetchRoles()
    }, [user, navigate, fetchRoles])

    return (
        <div>
            <ButtonBack />
            <Form form={formUpdate} onFinish={handleUpdate} layout="vertical">
                <Form.Item label='Tên đăng nhập'>
                    <Input placeholder={user.username} disabled />
                </Form.Item>
                <Form.Item name='role_id' label='Vai trò' initialValue={user.role?.id}
                    rules={[{ required: true, message: 'Vui lòng chọn vai trò!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn vai trò"
                        options={roleOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='active' label='Trạng thái tài khoản' initialValue={user.active}>
                    <Select
                        style={{ width: '100%' }}
                        options={userStatusOptions}
                    />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button disabled type="primary" onClick={() => setIsOpenModal(true)}>Đổi mật khẩu</Button>
                    <ButtonSubmitBack />
                </div>
            </Form>
            <Modal title='Đổi mật khẩu' open={isOpenModal}
                onCancel={() => {
                    setIsOpenModal(false)
                    formChangePassword.resetFields()
                }}
                footer={[
                    <Button type='primary' htmlType="submit" form='form-change-password'>
                        Đổi mật khẩu
                    </Button>,
                ]}>
                <Form
                    name="form-change-password"
                    form={formChangePassword}
                    onFinish={handleChangePassword}
                    labelAlign='left'
                    labelCol={{ span: 7 }}>
                    <Form.Item label='Mật khẩu cũ' name='old_password'
                        rules={[{ required: true, min: 6, message: 'Vui lòng nhập mật khẩu cũ và ít nhất 6 ký tự!' }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item label='Mật khẩu mới' name='password'
                        rules={[{ required: true, min: 6, message: 'Vui lòng nhập mật khẩu mới và ít nhất 6 ký tự!' }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item label='Nhắc lại mật khẩu' name='re_password'
                        rules={[{ required: true, min: 6, message: 'Vui lòng nhập nhắc lại mật khẩu và ít nhất 6 ký tự!' }]}>
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
