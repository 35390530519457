import axios from 'axios'
import qs from 'qs'

const cleanParams = (params: any) => {
  for (let [key, value] of Object.entries(params)) {
    // If value is array string, filter empty string and duplicate value
    if (Array.isArray(value) && value.every(it => typeof it === 'string')) {
      let newValue = value.filter(it => it)
      params[key] = Array.from(new Set(newValue as string[]))
    }

    // Remove all string value that equals "", undefined, null.
    if (typeof value === 'string' && !value) {
      delete params[key]
    }
  }

  return params
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: (url: string, params?: any) => {
    params = params || {}
    params = cleanParams(params)
    return axios.get(url, { params, paramsSerializer: pr => qs.stringify(pr, { arrayFormat: 'repeat' }) })
  },
  post: (url: string, data: any, config?: any) => axios.post(url, data, config),
  put: (url: string, data?: any) => axios.put(url, data),
  patch: (url: string, data: any) => axios.patch(url, data),
  delete: (url: string) => axios.delete(url),
}
