import { Divider, Modal, Space } from 'antd'
import { formatCurrency } from '../../utils/string-utils'
import { formatDate } from '../../utils/entity-utils'
import { renderTypePackages } from './ViewOverview'

type FieldModalView = {
    modalView: { data: any | null, isOpen: boolean }
    setModalView: Function
}

export default function ViewPartner({ modalView, setModalView }: FieldModalView) {
    return (
        <Modal
            title='Chi tiết gói cước'
            open={modalView.isOpen}
            footer={[]}
            onCancel={() => setModalView({ data: null, isOpen: false })}>
            <Divider />
            <Space>
                <h3 style={{ margin: 0 }}>Gói cước đối tác:</h3>
                <span>{modalView.data?.company?.name}</span>
            </Space>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <p><b>Tên gói: </b>{modalView.data?.name}</p>
                    <p><b>Mã gói: </b>{modalView.data?.code}</p>
                </div>
                <div>
                    <p><b>Giá bán: </b>{formatCurrency(modalView.data?.price)}</p>
                    <p><b>Giá trả lại: </b>{formatCurrency(modalView.data?.price_cashback)}</p>
                </div>
            </div>
            <div><b>Mô tả: </b>{modalView.data?.description}</div>
            <p><b>Cập nhật: </b>{formatDate(modalView.data?.modify)}</p>
            <Divider />
            <h3 style={{ margin: 0 }}>Gói cước gốc</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <p><b>Tên gói: </b>{modalView.data?.package?.name}</p>
                    <p><b>Mã gói: </b>{modalView.data?.package?.code}</p>
                    <p><b>Chu kỳ tính cước: </b>{renderTypePackages(modalView.data?.package?.type)}</p>
                </div>
                <div>
                    <p><b>Giá gốc: </b>{formatCurrency(modalView.data?.package?.price)}</p>
                    <p><b>Giá khuyến mãi: </b>{formatCurrency(modalView.data?.package?.price_discount)}</p>
                </div>
            </div>
            <div><b>Mô tả: </b>{modalView.data?.package?.description}</div>
            <p><b>Cập nhật: </b>{formatDate(modalView.data?.package?.modify)}</p>
        </Modal>
    )
}
