import axios from 'axios'
import { APIError } from './errors'
import { AUTH_TOKEN_KEY } from '../config/constants'

export const setupAxiosInterceptors = (onUnauthenticated: Function) => {
  // Setup request interceptor
  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      config.headers.Accept = 'application/json'
    }
    config.headers.Accept = 'application/x-www-form-urlencoded'

    return config
  })

  // Setup response interceptor
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      console.log('response', err, err?.response?.data?.message)
      return Promise.reject(new APIError(err?.response?.data?.errors?.[0]?.title ?? err?.response?.data?.message
        , err.response.status))
    },
  )
}
