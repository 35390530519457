import { Tag } from "antd"
import { CheckOutlined, LockOutlined } from '@ant-design/icons'

export default function StatusTag({ status }: { status: boolean | undefined }) {
    return (
        status ? (
            <Tag icon={<CheckOutlined />} color='green'>
                Hoạt động
            </Tag>
        ) : (
            <Tag icon={<LockOutlined />} color='red'>
                Đang khoá
            </Tag>
        )
    )
}
