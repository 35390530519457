import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

export const useAppTranslation = (namespaces: string[], opt?: any) => {
    const { t } = useTranslation(namespaces, opt)
    function translate(key: any, opt?: any) {
        return t(key, opt) as string
    }
    return {
        translate: translate
    }
}

export const useGetContentTranslation = (key: any, namespaces: string, opt?: any): string => {
    const { t } = useTranslation(namespaces)
    const [content, setContent] = useState('')
    useEffect(() => {
        const msg = t(key, opt) as string
        setContent(msg)
    }, [t, key, opt])
    return content
}