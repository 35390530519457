import { Form, Input, Select } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiGetListRoles, apiCreateUserPartner, apiGetListCompanies } from "../../api"
import { useCallback, useEffect, useState } from "react"
import defaultParams from "../../assets/constants/default_params"
import { IRole } from "../../models/role.model"
import { filterOptionByLabel } from "../../utils/filter-utils"
import { ADMIN_USERNAME, SECRET_KEY } from "../../config/constants"
import md5 from "blueimp-md5"
import { ICompany } from "../../models/company.modal"

export default function UserPartnerCreate() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [roleOptions, setRoleOptions] = useState<{ label: string, value: Number }[]>([])
    const [formCreate] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchOptions = useCallback(async () => {
        try {
            setIsLoading(true)
            const [respCompany, respRole] = await Promise.all([
                apiGetListCompanies({ limit: defaultParams.MAX_LIMIT }),
                apiGetListRoles({ limit: defaultParams.MAX_LIMIT }),
            ])
            if (respRole.data?.roles === null && respCompany.data?.companies === null) {
                return
            }
            const compOptions = respCompany.data.companies.map((item: ICompany) => ({
                label: `${item.name} (${item.code})`,
                value: item.code
            }))
            setCompanyOptions(compOptions)
            const listRoles = respRole.data.roles.filter((item: IRole) => item.name !== 'Super Admin')
            const roleOptions = listRoles.map((item: IRole) => ({
                label: item.name,
                value: item.id
            }))
            setRoleOptions(roleOptions)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchOptions()
    }, [fetchOptions])

    const handleCreate = async ({ username, cpCode, password, role_id }: { username: string, cpCode: string, password: string, role_id: Number }) => {
        try {
            setIsLoading(true)
            let hashPassword = password
            if (username !== ADMIN_USERNAME) {
                hashPassword = md5(password, SECRET_KEY)
            }
            const resp = await apiCreateUserPartner({ username, cpCode, password: hashPassword, role_id })
            if (resp.status === 200) {
                toastSuccess('Thêm quản trị viên mới thành công!')
                formCreate.resetFields()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Form form={formCreate} onFinish={handleCreate} layout="vertical" autoComplete="off">
                <Form.Item name='username' label='Tên đăng nhập'
                    rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='cpCode' label='Mã công ty'
                    rules={[{ required: true, message: 'Vui lòng chọn mã công ty!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn công ty"
                        options={companyOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <Form.Item name='password' label='Mật khẩu'
                    rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập!' }]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item name='role_id' label='Vai trò'
                    rules={[{ required: true, message: 'Vui lòng chọn vai trò!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn vai trò"
                        options={roleOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <ButtonSubmitBack />
            </Form>
        </div>
    )
}
