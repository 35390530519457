import { Button, Dropdown, Menu } from 'antd'
import { DownOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

type FieldEditDelete = {
    canUpdate: boolean,
    canDelete: boolean,
    dataUpdate: any,
    linkUpdate: string,
    hanldeDelete: Function,
    otherFields?: any[],
}

export default function ActionEditDelete({ canUpdate, canDelete, dataUpdate, linkUpdate, hanldeDelete, otherFields }: FieldEditDelete) {
    const isUpdateOrDelete = canUpdate || canDelete

    return (
        isUpdateOrDelete || otherFields ? <Dropdown
            overlay={
                <Menu>
                    {canUpdate && <Menu.Item>
                        <Link state={dataUpdate} to={linkUpdate}>
                            <Button style={{ width: 100 }} icon={<EditOutlined />} type='primary'>
                                Sửa
                            </Button>
                        </Link>
                    </Menu.Item>}
                    {canDelete && <Menu.Item>
                        <Button style={{ width: 100 }} onClick={() => hanldeDelete()}
                            icon={<DeleteOutlined />} type='primary' danger>
                            Xoá
                        </Button>
                    </Menu.Item>}
                    {otherFields?.map(field => (
                        <Menu.Item>
                            {field}
                        </Menu.Item>
                    ))}
                </Menu>
            }
        >
            <Button style={{ background: '#289e45', color: 'white' }}>
                Chức năng <DownOutlined />
            </Button>
        </Dropdown> : <></>
    )
}
