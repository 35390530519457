export const customerInfo = {
    customer_id: 'customer-5',
    ownerBy: 'nghiaved',
    full_name: 'Nguyễn Thành Nghĩa',
    phone_number: '0902222222',
    email: 'nghia@gmail.com',
    address: 'Hà Nội',
    nation: 'Vietnam',
    number_identity: '1249579381827',
    type_identity: 'Passport',
    expire_date: '08/07/2025',
    createdAt: '07/17/2024',
}

export const listTransByCustomer = [
    {
        "id": 1,
        "ownerBy": "nghia",
        "customerName": "Nguyen Thanh Nghia",
        "customer": "0338588826",
        "package": "6M128",
        "company": "Otek",
        "msisdn": "1699449028",
        "serialSim": "8984048000934412211",
        "startDate": "2024-07-08",
        "endDate": "2025-07-03",
        "description": "",
        "modify": "2024-07-08",
        "status": "SUCCESS",
    },
    {
        "id": 2,
        "ownerBy": "nghia",
        "customerName": "Nguyen Văn A",
        "customer": "0338588826",
        "package": "12M128",
        "company": "ZesTech",
        "package_id": 5,
        "company_id": 4,
        "msisdn": "1699449028",
        "serialSim": "8984048000934412211",
        "startDate": "2024-06-25",
        "endDate": "2025-06-20",
        "description": "",
        "modify": "2024-06-25",
        "status": "SUCCESS",
    },
]