import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

export const isEmpty = (entity: any) => _isEmpty(entity);
export const cloneDeep = (entity: any) => _cloneDeep(entity);
export const formatCurrency = (number: number) => Intl.NumberFormat('en-US').format(number) + ' đ';
export const formatNumber = (number: number) => Intl.NumberFormat('en-US').format(number);
export const formatShortCurrency = (number: number) => {
    switch (true) {
        case number >= 1e9: {
            const billions = (number / 1_000_000_000).toFixed(2)
            return `${billions} tỷ`
        }
        case number >= 1e6: {
            const millions = Math.floor(number / 1e6)
            return `${millions} tr`
        }
        default: {
            return formatCurrency(number)
        }
    }
}