import { Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Typography } from 'antd'
import { apiRenewTransaction } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { toastError, toastSuccess } from '../../utils/toast'
import { IRenewTransaction, ITransaction } from '../../models/transaction.modal'
import { ITelegrams } from '../../models/telegram.modal'
import { useEffect, useState } from 'react'
import { filterOptionByLabel } from '../../utils/filter-utils'
const { Text } = Typography

type FieldModalRenew = {
    modalRenew: { data: ITransaction | null, isOpen: boolean }
    setModaRenew: Function
    fetchData: Function
    packageOptions: { label: string, value: Number }[]
    telegrams: ITelegrams
}

export default function ModalRenew({ modalRenew, setModaRenew, fetchData, packageOptions, telegrams }: FieldModalRenew) {
    const [packages, setPackages] = useState<{ label: string, value: Number }[]>([])
    const [formRenew] = Form.useForm()

    const { setIsLoading } = useLoading()

    useEffect(() => {
        if (packageOptions && telegrams && modalRenew.data?.company?.id) {
            const listPackageOfCompany = telegrams
                .telegrams?.filter(item => item.company?.id === modalRenew.data?.company?.id)
                .map(item => item.package?.id)
            const result = packageOptions.filter(item => listPackageOfCompany?.includes(item.value))
            setPackages(result)
        }
    }, [packageOptions, telegrams, modalRenew.data?.company?.id])

    const handleRenew = async ({ package_id }: { package_id: Number }) => {
        try {
            setIsLoading(true)
            const values: IRenewTransaction = {
                id: modalRenew.data?.id,
                package_id: package_id,
                company_id: modalRenew.data?.company?.id,
            }
            const resp = await apiRenewTransaction(values)
            if (resp.status === 200) {
                toastSuccess('Gia hạn giao dịch thành công!')
                fetchData()
                setModaRenew({ data: null, isOpen: false })
                formRenew.resetFields()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <Modal
            title='Gia hạn giao dịch'
            open={modalRenew.isOpen}
            onCancel={() => {
                setModaRenew({ data: null, isOpen: false })
                formRenew.resetFields()
            }}
            footer={[
                <Button type='primary' htmlType="submit" danger form='form-renew'>
                    Gia hạn
                </Button>,
            ]}>
            <Form
                form={formRenew}
                name='form-renew'
                style={{ marginTop: 20 }}
                onFinish={handleRenew}
                labelAlign='left'
                labelCol={{ span: 10 }}>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Space>
                            <Text strong>Khách hàng:</Text>
                            {modalRenew.data?.msisdn}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space>
                            <Text strong>Số điện thoại:</Text>
                            {modalRenew.data?.customer}
                        </Space>
                    </Col>
                </Row>
                <Divider />
                <Form.Item label='Công ty'>
                    <Input style={{ width: '100%' }} value={`${modalRenew.data?.company?.id} - ${modalRenew.data?.company?.name}`} disabled />
                </Form.Item>
                <Form.Item name='package_id' label='Gói cước' rules={[{ required: true, message: 'Vui lòng chọn gói cước!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn gói cước"
                        options={packages}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
