import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Select, Space } from 'antd'
import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListCompanies, apiGetListTransactionConnection, apiGetListUsers } from '../../api'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { mbhStatusOptions, transactionConnectionTypeOptions } from '../../assets/constants/default_options'
import { ICompany } from '../../models/company.modal'
import { transactionConnectionColumns } from './method'
import { IUser } from '../../models/user.model'

export default function KitSimOrder() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [userOptions, setUserOptions] = useState<{ label: string, value: Number }[]>([])
    const [transactionConnection, setTransactionConnection] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState<any>({})
    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()

    const fetchTransactionConnection = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionConnection({
                page: currentPage,
                size: pageSize,
                cpCode: search.cpCode,
                ownerBy: search.ownerBy,
                type: search.type,
                status: search.status,
            })
            if (resp.status === 200) {
                setTransactionConnection(resp.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    const fetchCompanies = useCallback(async () => {
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.companies.map((item: ICompany) => ({
                    label: item.name,
                    value: item.code,
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchUsers = useCallback(async () => {
        try {
            const resp = await apiGetListUsers({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.users.map((item: IUser) => ({
                    label: item.username,
                    value: item.username,
                }))
                setUserOptions(options)
            } else {
                setUserOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchTransactionConnection()
        fetchCompanies()
        fetchUsers()
    }, [fetchTransactionConnection, fetchCompanies, fetchUsers])

    const handleSearch = ({ cpCode, ownerBy, type, status }: any) => {
        if (!cpCode && !ownerBy && !type && !status) return
        setSearch({ cpCode, ownerBy, type, status })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({})
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    return (
        <div>
            <Form form={formSearch} onFinish={handleSearch}>
                <div style={{ display: 'flex', alignItems: 'start', flexWrap: 'wrap', gap: 20 }}>
                    <Form.Item name='cpCode' label='Công ty'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='ownerBy' label='Nhân sự đấu nối'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn nhân sự"
                            options={userOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='type' label='Loại giao dịch'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn loại giao dịch"
                            options={transactionConnectionTypeOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='status' label='Trạng thái'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn trạng thái"
                            options={mbhStatusOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space style={{ marginBottom: 24 }}>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <CustomTable
                namePage='giao dịch'
                columns={transactionConnectionColumns}
                dataSource={transactionConnection.transactions}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={transactionConnection.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}
