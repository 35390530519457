import { useCallback, useEffect, useState } from 'react'
import { TableProps } from 'antd'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListPackagesTkgDashboard } from '../../api'
import { formatCurrency } from '../../utils/string-utils'

export default function RootAccountDetailPackage() {
    const [tkgPackages, setTkgPackages] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)

    const { setIsLoading } = useLoading()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: 'STT',
            render: (value, record, index) => ++index
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'code',
            title: 'Mã gói cước',
            dataIndex: 'code',
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: (price) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            )
        },
        {
            key: 'description',
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            key: 'type',
            title: 'Loại gói cước',
            dataIndex: 'type',
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resPackages = await apiGetListPackagesTkgDashboard()
            if (resPackages.data?.partner_packages) {
                setTkgPackages(resPackages.data?.partner_packages)
            } else {
                setTkgPackages([])
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <div>
            <CustomTable
                namePage='gói cước'
                columns={columns}
                dataSource={tkgPackages}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={tkgPackages?.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isHiddenPagination={true}
            />
        </div>
    )
}
