import { Line } from "react-chartjs-2"
import { colorPalette } from "../../utils/color-utils"

export default function ChartType({ totalPeriodByMonth }: any) {
    const monthlyTotals = totalPeriodByMonth[0]?.months?.map((_: any, monthIndex: number) => {
        return totalPeriodByMonth.reduce((sum: number, period: any) => {
            return sum + (period.months[monthIndex]?.sum || 0);
        }, 0);
    });

    const data = {
        labels: totalPeriodByMonth[0]?.months?.map((item: any) => item.month),
        datasets: totalPeriodByMonth.map((period: any, index: any) => {
            return {
                label: `${period?.numberDay / 30} tháng`,
                data: period?.months?.map((month: any, monthIndex: number) => {
                    const monthlyTotal = monthlyTotals[monthIndex];
                    if (month.sum == 0 || monthlyTotal == 0) return 0
                    const percentage = ((month.sum / monthlyTotal) * 100).toFixed(2);
                    return parseFloat(percentage)
                }),
                borderWidth: 2,
                datalabels: {
                    color: colorPalette[index],
                },
            };
        }),
    } as any;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Tỷ lệ đấu nối gói theo chu kỳ',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                font: {
                    size: 14,
                    weight: 'bold',
                },
                anchor: 'end',
                align: 'top',
                formatter: (value: any) => `${value}%`.replace('.00', ''),
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    } as any

    return (
        <Line data={data} options={options} />
    )
}
