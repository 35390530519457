import { Form, Input } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiCreateRole } from "../../api"
import { convertListStringToPermission } from "../../utils/perm-utils"
import { ListFormCheckbox } from "./FormCheckbox"
import { permissionOptions } from "../../assets/constants/default_options"
import { IFormValueRole } from "../../models/role.model"

export default function RoleCreate() {
    const [formCreate] = Form.useForm()
    const { setIsLoading } = useLoading()

    const handleCreate = async ({ name, description, ...groups }: IFormValueRole) => {
        try {
            setIsLoading(true)
            const perms = Object
                .entries(groups)
                .map(([_, value]) => convertListStringToPermission(value))
                .flat(Infinity) as []
            const resp = await apiCreateRole({
                name: name,
                description: description,
                permissions: perms,
            })
            if (resp.status === 200) {
                toastSuccess('Thêm phân quyền mới thành công!')
                formCreate.resetFields()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Form form={formCreate} onFinish={handleCreate} layout="vertical">
                <Form.Item name='name' label='Tên phân quyền'
                    rules={[{ required: true, message: 'Vui lòng nhập tên phân quyền!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='description' label='Mô tả'>
                    <Input />
                </Form.Item>
                <ListFormCheckbox options={permissionOptions} />
                <ButtonSubmitBack />
            </Form>
        </div>
    )
}
