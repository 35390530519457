import { Doughnut } from 'react-chartjs-2'
import { formatCurrency, formatShortCurrency } from '../../utils/string-utils'

export default function ChartRevenue({ revenue }: any) {
    const data = {
        labels: revenue.dataPacks?.map((item: any) => item.dataPack),
        datasets: [
            {
                data: revenue.dataPacks?.map((item: any) => item.sum),
                borderWidth: 2,
            },
        ],
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => formatCurrency(context.raw),
                },
            },
            title: {
                display: true,
                text: 'Doanh thu các gói',
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                formatter: (value: any) => formatShortCurrency(value),
                color: '#000',
                font: {
                    weight: 'bold',
                    size: 14,
                },
            },
        },
    } as any

    return (
        <Doughnut
            key={revenue.total}
            data={data} options={options} plugins={[{
            id: 'centerText',
            afterDraw: function (chart: any) {
                const { ctx, width, height } = chart
                ctx.restore()
                ctx.font = `16px sans-serif`
                ctx.textBaseline = 'middle'
                const text = formatShortCurrency(revenue.total)
                const textX = Math.round((width - ctx.measureText(text).width) / 2)
                const textY = height / 2
                ctx.fillText(text, textX, textY)
                ctx.save()
            },
        }]}
        />
    )
}
