import { useCallback, useEffect, useState } from 'react'
import { Button, Flex, Form, Progress, Select, Space, TableProps, Tag } from 'antd'
import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListTransactionTopupsByOrderId } from '../../api'
import moment from 'moment'
import ButtonBack from '../../components/button/ButtonBack'
import { useSearchParams } from 'react-router-dom'
import { mbhStatusOptions } from '../../assets/constants/default_options'
import { filterOptionByLabel } from '../../utils/filter-utils'

export default function KitSimDetailTransaction() {
    const [tranTopups, setTranTopups] = useState<any>()
    const [progessPercent, setProgessPercent] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({ status: '' })

    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId')

    const columns: TableProps<any>['columns'] = [
        {
            key: 'tranId',
            title: 'Mã giao dịch',
            dataIndex: 'tranId',
            render: tranId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {tranId}
                </span>
            ),
        },
        {
            key: 'orderId',
            title: 'Mã đơn hàng',
            dataIndex: 'orderId',
            render: orderId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {orderId}
                </span>
            ),
        },
        {
            key: 'id',
            title: 'Mã khách hàng',
            render: (value, recore, index) => `customer-${++index}`
        },
        {
            key: 'msisdn',
            title: 'Msisdn',
            dataIndex: 'customer',
        },
        {
            key: 'serialSim',
            title: 'SerialSim',
            dataIndex: 'tranId',
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
            render: () => '12M128'
        },
        {
            key: 'code',
            title: 'Mã gói cước',
            dataIndex: 'code',
            render: () => '12M128'
        },
        {
            key: 'startDate',
            title: 'Ngày bắt đầu',
            dataIndex: 'updatedAt',
            render: (updatedAt) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(updatedAt)).format('YYYY-MM-DD')}
                </span>
            )
        },
        {
            key: 'endDate',
            title: 'Ngày kết thúc',
            dataIndex: 'updatedAt',
            render: (updatedAt) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(updatedAt)).format('YYYY-MM-DD')}
                </span>
            )
        },
        {
            key: 'updatedAt',
            title: 'Cập nhật',
            dataIndex: 'updatedAt',
            render: (updatedAt) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(updatedAt)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status)
        },
    ]

    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === 'SUCCESS') return <Tag style={style} color="#9b9b05">Thành công</Tag>
        if (state === 'FAIL') return <Tag style={style} color="#c93c3c">Thất bại</Tag>
        if (state === 'ERROR') return <Tag style={style} color="#c93c3c">Lỗi</Tag>
        if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
        return <Tag style={style} color="#2faac6">Đã tạo</Tag>
    }

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionTopupsByOrderId({
                orderId: orderId,
                page: currentPage,
                size: pageSize,
                status: search.status,
            })
            if (resp.data != null) {
                setTranTopups(resp.data)
                const respFull = await apiGetListTransactionTopupsByOrderId({
                    orderId: orderId,
                    page: defaultParams.PAGE,
                    size: defaultParams.MAX_LIMIT,
                })
                if (respFull.data != null) {
                    let totalProgess = 0
                    respFull.data.trans?.map((item: any) => {
                        if (item.status === 'SUCCESS' || item.status === 'FAIL' || item.status === 'ERROR') {
                            totalProgess++
                        }
                    })
                    if (totalProgess > 0) {
                        const percent = Number(totalProgess / respFull.data.total * 100).toFixed(2)
                        setProgessPercent(Number(percent))
                    }
                }
            } else {
                setTranTopups({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, orderId, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ status }: any) => {
        if (status) {
            setSearch({ status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    return (
        <div>
            <ButtonBack />
            <h2>Mã đơn hàng: {orderId}</h2>
            <Form form={formSearch} onFinish={handleSearch}>
                <Space style={{ display: 'flex', alignItems: 'start' }}>
                    <Form.Item name='status' label='Trạng thái'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn trạng thái"
                            options={mbhStatusOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </Space>
            </Form>
            <Space style={{ marginBottom: 20 }}>
                Đã xử lý: <Flex gap="small" vertical>
                    <Progress style={{ width: 300 }} percent={progessPercent} status="active" />
                </Flex>
            </Space>
            <CustomTable
                namePage='giao dịch'
                columns={columns}
                dataSource={tranTopups?.trans}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={tranTopups?.total || tranTopups?.trans?.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}
