import { Button } from "antd"
import { LeftCircleOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

export default function ButtonBack() {
    const navigate = useNavigate()

    const handleBackPage = () => {
        navigate(-1)
    }

    return (
        <Button
            onClick={handleBackPage}
            icon={<LeftCircleOutlined />}
            style={{ background: 'gray', color: 'white', marginBottom: 20 }}
        >
            Trở lại
        </Button>
    )
}
