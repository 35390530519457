import { Checkbox, Form } from "antd"
import { convertListGroupPermissionToString } from "../../utils/perm-utils"
import { IOptionRole, IPermission } from "../../models/role.model"

type FieldType = {
    label: string,
    groupName: string,
    options: IOptionRole[],
    initValues?: (string | false)[],
}

const FormCheckbox = ({ label, groupName, options, initValues }: FieldType) => {
    return (
        <Form.Item name={groupName} label={label} initialValue={initValues}>
            <Checkbox.Group style={{ flexDirection: 'column', gap: 10 }}
                options={options.filter(item => item.group === groupName)} />
        </Form.Item>
    )
}

type ListFieldType = {
    options: IOptionRole[],
    initValues?: IPermission[],
}

export const ListFormCheckbox = ({ options, initValues }: ListFieldType) => {
    const groups = Array.from(
        new Map(options.map(item => [item.group, item])).values()
    )

    return (
        <>
            {groups.map(item => (
                <FormCheckbox
                    key={item.group}
                    label={item.name}
                    options={options}
                    groupName={item.group}
                    initValues={initValues && convertListGroupPermissionToString(initValues, item.group)}
                />
            ))}
        </>
    )
}
