import { Form, Input, Select } from "antd"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonBack from "../../components/button/ButtonBack"
import ButtonSubmitBack from "../../components/button/ButtonSubmitBack"
import { useLoading } from "../../context/LoadingContext"
import { apiGetListRoles, apiCreateUser } from "../../api"
import { useCallback, useEffect, useState } from "react"
import defaultParams from "../../assets/constants/default_params"
import { IRole } from "../../models/role.model"
import { filterOptionByLabel } from "../../utils/filter-utils"
import { ADMIN_USERNAME, SECRET_KEY } from "../../config/constants"
import md5 from "blueimp-md5"

export default function UserCreate() {
    const [roleOptions, setRoleOptions] = useState<{ label: string, value: Number }[]>([])
    const [formCreate] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchRoles = useCallback(async () => {
        try {
            setIsLoading(true)
            const resRoles = await apiGetListRoles({ limit: defaultParams.MAX_LIMIT })
            if (resRoles.data != null) {
                const listRoles = resRoles.data.roles.filter((item: IRole) => item.name !== 'Super Admin')
                const options = listRoles.map((item: IRole) => ({
                    label: item.name,
                    value: item.id
                }))
                setRoleOptions(options)
            } else {
                setRoleOptions([])
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading])

    useEffect(() => {
        fetchRoles()
    }, [fetchRoles])

    const handleCreate = async ({ username, password, role_id }: { username: string, password: string, role_id: Number }) => {
        try {
            setIsLoading(true)
            let hashPassword = password
            if (username !== ADMIN_USERNAME) {
                hashPassword = md5(password, SECRET_KEY)
            }
            const resp = await apiCreateUser({ username, password: hashPassword, role_id })
            if (resp.status === 200) {
                toastSuccess('Thêm quản trị viên mới thành công!')
                formCreate.resetFields()
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack />
            <Form form={formCreate} onFinish={handleCreate} layout="vertical">
                <Form.Item name='username' label='Tên đăng nhập'
                    rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='password' label='Mật khẩu'>
                    <Input.Password />
                </Form.Item>
                <Form.Item name='role_id' label='Vai trò'
                    rules={[{ required: true, message: 'Vui lòng chọn vai trò!' }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Chọn vai trò"
                        options={roleOptions}
                        filterOption={filterOptionByLabel}
                    />
                </Form.Item>
                <ButtonSubmitBack />
            </Form>
        </div>
    )
}
